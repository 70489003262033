import React from "react";
import { Form } from "react-bootstrap";

function _selectOptionsRender(control) {
  return control.selectOptions.map((el, idx) => {
    return (
      <option key={idx} value={el[0]}>
        {el[1]}
      </option>
    );
  });
}

const onKeyUpHandler = (targetHandler) => (e) => {
  if (e.keyCode === 13 && typeof targetHandler === "function") targetHandler();
};

function renderControl(
  id,
  control,
  onChangeHandler,
  onEnterKeyHandler,
  size,
  params = {},
) {
  control = control[id];

  let cls = [];

  if (control.errorMessage) {
    cls.push("is-invalid");
  }

  if (control.type === "select") {
    cls.push("form-control");

    return (
      <Form.Group className={size}>
        <Form.Label htmlFor={"fc_" + id}>{control.label}</Form.Label>
        <Form.Select
          id={"fc_" + id}
          name={id}
          className={cls.join(" ")}
          onChange={onChangeHandler()}
          value={control.value ?? ""}
        >
          {_selectOptionsRender(control)}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {control.errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  if (control.type === "checkbox") {
    cls.push("form-check-input");

    return (
      <Form.Group className={`${size} chbirow_t`}>
        <input
          id={`fc_${id}`}
          name={id}
          type="checkbox"
          onChange={onChangeHandler()}
          checked={control.checked ?? false}
          className={cls.join(" ")}
          disabled={params.disabled}
        />
        <Form.Label
          htmlFor={`fc_${id}`}
          className={params.disabled ? "disabled" : null}
        >
          {control.label}
        </Form.Label>
        <Form.Control.Feedback type="invalid">
          {control.errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  if (control.type === "datePicker") {
    const value = control.value
      ? control.value.match(/^\d{4,5}-\d{2}-\d{2}/)[0]
      : "";
    const onChange =
      typeof onChangeHandler === "function" ? onChangeHandler() : null;
    return (
      <Form.Group className={size}>
        <Form.Label
          htmlFor={"fc_" + id}
          className={params.disabled ? "disabled" : null}
        >
          {control.label}
        </Form.Label>
        <Form.Control
          id={`fc_${id}`}
          name={id}
          type="date"
          value={value}
          onChange={onChange}
          {...params}
        />
        <Form.Control.Feedback type="invalid">
          {control.errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  let textControlParams = {
    id: `fc_${id}`,
    name: id,
    onKeyUp: onKeyUpHandler(onEnterKeyHandler),
    type: "text",
    isInvalid: control.errorMessage,
    value: control.value ?? "",
    onChange: onChangeHandler(),
    placeholder: control.placeholder ?? "",
    title: control.title ?? "",
  };

  if (control.type === "textarea") {
    delete textControlParams.type;
    delete textControlParams.onKeyUp;
    textControlParams.as = "textarea";
  } else if (control.type === "numeric") {
    textControlParams.onChange = function (e) {
      const val = e.target.value;
      if (
        val === "" ||
        val === "-" ||
        (control.mask.test(val) && val >= control.min && val <= control.max)
      ) {
        onChangeHandler()(e);
      }
    };
  }

  return (
    <Form.Group className={size}>
      <Form.Label
        htmlFor={"fc_" + id}
        className={params.disabled ? "disabled" : null}
      >
        {control.label}
      </Form.Label>
      <Form.Control {...textControlParams} {...params} />
      <Form.Control.Feedback type="invalid">
        {control.errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export default renderControl;
