import Base from "../Base";
import React from "react";
import ZoneItem from "./ZoneItem";
import ZoneForm from "./ZoneForm";
import TableSearch from "../../../Components/General/TableSearch/TableSearch";
import { Modal } from "react-bootstrap";
import Error from "../../../Components/General/Alerts/error";
import AddBtn from "../../../Components/General/AddBtn";

import SearchInputObj from "../../../Components/General/SearchInputs/searchInputObj";
import Zone from "../../../Components/Zone";

import { permissions, canCreate } from "../../ServerPermissions/Permissions";

export default class Zones extends Base {
  static mainUrl = "zone";
  mainUrl = "zone";

  filter = (
    <SearchInputObj
      size={12}
      label="Родительская зона"
      source="zone"
      server={this.props.server}
      render={(item) => <Zone item={item} />}
      onSelectItem={(item) => this.setFilter("parent", item.ID)}
    />
  );

  render() {
    return (
      <div className="inmodule">
        <TableSearch
          filter={this.filter}
          searchParams={this.searchParams}
          setParentCallback={() => this.setCallback()}
        />

        <div className="centerCount">
          Показано {this.state.cards.length} из {this.state.totalCount}
        </div>

        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Название</th>
              <th scope="col" style={{ width: "500x" }}>
                Родительская зона
              </th>
              <th scope="col" style={{ width: "150px" }}>
                Параметры
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.cards.map((item) => {
              return (
                <ZoneItem key={item.ID} info={item} onClick={this.showModal} />
              );
            })}
          </tbody>
        </table>

        {this.state.cards.length < this.state.totalCount
          ? this.getMoreButton()
          : null}

        <div style={{ height: "60px" }}></div>

        {canCreate(permissions.zone, this.props.serverPermissions) ? (
          <AddBtn onClick={this.newClick()} />
        ) : null}

        <Modal
          show={this.state.modalShow}
          onHide={this.hideEditModal}
          size="xl"
          fullscreen="lg-down"
        >
          <ZoneForm
            id={this.state.modalItemId}
            hideCallback={this.hideEditModal}
            parentReloadCallback={this.reloadCallback}
            server={this.props.server}
            mainUrl={this.mainUrl}
            serverPermissions={this.props.serverPermissions}
          />
        </Modal>

        {this.state.errorconnect ? <Error /> : null}
      </div>
    );
  }
}
