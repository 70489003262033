import BaseForm from "../BaseForm";

import React from "react";
import { Button, Modal, Nav, Row } from "react-bootstrap";

import FormControlsRender from "../../../Utils/FormControlsRender";
import { Text, TextArea, DatePicker } from "../../../Utils/FormControlsPresets";
import SearchInputObj from "../../../Components/General/SearchInputs/searchInputObj";
import Tab from "../../../Components/General/Tab";
import Files from "../../../Components/General/FilesZone/FileZone";
import Guid from "../../../Components/Guid";
import ConfirmModal from "../../../Components/General/ConfirmModal";
import SensorForm from "../sensors/SensorForm";
import LeakageForm from "../leakages/LeakageForm";
import CounterForm from "../counters/CounterForm";
import ModemForm from "../modems/ModemForm";
import ValveNbiotForm from "../valves_nbiot/ValveNbiotForm";
import Counter from "../../../Components/Devices/Counter";
import Sensor from "../../../Components/Devices/Sensor";
import LeakageSensor from "../../../Components/Devices/LeakageSensor";
import Modem from "../../../Components/Devices/Modem";
import ValveNbiot from "../../../Components/Devices/ValveNbiot";
import Services from "./Services";
import Zone from "../../../Components/Zone";

export default class ContractForm extends BaseForm {
  constructor(props) {
    super(props);

    this.state = {
      navKey: "1",
      requestProcess: false,
      card: {},
      formControls: {
        TITLE: Text("Наименование"),
        CONTACTS: TextArea("Контакты"),
        PRIM: TextArea("Примечание"),
        DATEON: DatePicker("Дата создания записи"),
        DATE_DELIVERY: DatePicker("Дата поставки оборудования"),
        DATE_WARRANTY_END: DatePicker("Дата окончания гарантии"),
        DATE_SUPPORT_END: DatePicker("Дата окончания технической поддержки"),
        DATE_TRAFFIC_END: DatePicker("Трафик оплачен до"),
        TRAFFIC_TYPE: Text("Вид трафика"),
        TYPE: {},
        PARENT: {},
        CONTRACTOR: {},
        ZONE: {},
      },
      deviceModal: {},
      postData: {},
    };

    this.id = props.id;
  }

  changeDateDelivery = () => (e) => {
    const match = e.target.value.match(/^\d{4}-\d{2}-\d{2}/);

    if (!match && e.target.value !== "") {
      return;
    }

    const date = match ? match[0] : null;

    let dateEnd = new Date(date);
    dateEnd.setMonth(dateEnd.getMonth() + 12);
    const textDateEnd = dateEnd.toISOString().substring(0, 10);

    const formControls = this.state.formControls;
    formControls.DATE_DELIVERY.value = date;
    formControls.DATE_WARRANTY_END.value = textDateEnd;
    formControls.DATE_SUPPORT_END.value = textDateEnd;
    formControls.DATE_TRAFFIC_END.value = textDateEnd;
    this.setState({
      formControls,
      postData: Object.assign({}, this.state.postData, {
        DATE_DELIVERY: date,
        DATE_WARRANTY_END: textDateEnd,
        DATE_SUPPORT_END: textDateEnd,
        DATE_TRAFFIC_END: textDateEnd,
      }),
    });
  };

  devModalShow = (device, type) =>
    this.setState({ deviceModal: { show: true, device, type } });
  devModalHide = () => this.setState({ deviceModal: {} });

  render() {
    const isNotSpecification =
      this.state.id || this.state.card.PARENT ? false : true;

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{`${this.id ? "Редактирование" : "Создание"} контракта`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Guid key={this.id} value={this.id} />
          </Row>
          <Row>
            {FormControlsRender(
              "TITLE",
              this.state.formControls,
              this.onChangeHandler,
              this.onSaveHandler(),
              "col-md-8",
            )}

            <SearchInputObj
              disabled={
                this.state.card?.PARENT?.ID &&
                !this.state.postData.hasOwnProperty("PARENT_ID")
                  ? true
                  : false
              }
              label="Вид сделки"
              source="contract-type"
              size="4"
              server={this.props.server}
              render={(item) => <>{item.TITLE}</>}
              onSelectItem={(item) => {
                this.setState({
                  postData: Object.assign({}, this.state.postData, {
                    TYPE_ID: item.ID ?? null,
                  }),
                });
              }}
              defaultItem={
                this.state.card?.TYPE ?? this.state.card?.PARENT?.TYPE ?? {}
              }
              errorMessage={this.state.formControls.TYPE?.errorMessage}
            />
          </Row>
          <Row>
            <SearchInputObj
              label="Основной договор"
              disabled={
                (this.state.card?.TYPE?.ID ||
                  this.state.card?.CONTRACTOR?.ID) &&
                !(
                  this.state.postData.hasOwnProperty("TYPE_ID") ||
                  this.state.postData.hasOwnProperty("CONTRACTOR_ID")
                )
                  ? true
                  : false
              }
              source="contract"
              size="12"
              server={this.props.server}
              render={(item) => (
                <>
                  {item.TITLE}{" "}
                  {item.CONTRACTOR?.TITLE
                    ? `(${item.CONTRACTOR?.TITLE})`
                    : null}
                </>
              )}
              onSelectItem={(item) => {
                this.setState({
                  postData: Object.assign({}, this.state.postData, {
                    PARENT_ID: item.ID ?? null,
                  }),
                });
              }}
              filter={{ mode: "contract" }}
              defaultItem={this.state.card?.PARENT ?? {}}
              errorMessage={this.state.formControls.PARENT?.errorMessage}
            />
          </Row>
          <Row>
            <SearchInputObj
              disabled={
                this.state.card?.PARENT?.ID &&
                !this.state.postData.hasOwnProperty("PARENT_ID")
                  ? true
                  : false
              }
              label="Контрагент"
              source="contractor"
              size="8"
              server={this.props.server}
              render={(item) => <>{item.TITLE}</>}
              onSelectItem={(item) => {
                this.setState({
                  postData: Object.assign({}, this.state.postData, {
                    CONTRACTOR_ID: item.ID ?? null,
                  }),
                });
              }}
              defaultItem={
                this.state.card?.CONTRACTOR ??
                this.state.card?.PARENT?.CONTRACTOR ??
                {}
              }
              errorMessage={this.state.formControls.CONTRACTOR?.errorMessage}
            />
            <SearchInputObj
              disabled={
                this.state.card?.PARENT?.ID &&
                !this.state.postData.hasOwnProperty("PARENT_ID")
                  ? true
                  : false
              }
              label="Зона"
              source="zone"
              size="4"
              server={this.props.server}
              render={(item) => <Zone item={item} />}
              onSelectItem={(item) =>
                this.setState({
                  postData: Object.assign({}, this.state.postData, {
                    ZONE_ID: item.ID ?? null,
                  }),
                })
              }
              defaultItem={
                this.state.card?.ZONE ?? this.state.card?.PARENT?.ZONE ?? {}
              }
              errorMessage={this.state.formControls.CONTRACTOR?.errorMessage}
            />
          </Row>
          <Row>
            {FormControlsRender(
              "DATE_DELIVERY",
              this.state.formControls,
              this.changeDateDelivery,
              this.onSaveHandler(),
              "col-md-4",
              { disabled: isNotSpecification },
            )}
            {FormControlsRender(
              "DATE_WARRANTY_END",
              this.state.formControls,
              this.onChangeDPHandler,
              this.onSaveHandler(),
              "col-md-4",
              { disabled: isNotSpecification },
            )}
            {FormControlsRender(
              "DATEON",
              this.state.formControls,
              this.onChangeDPHandler,
              this.onSaveHandler(),
              "col-md-4",
              { disabled: true },
            )}
          </Row>
          <Row>
            {FormControlsRender(
              "DATE_SUPPORT_END",
              this.state.formControls,
              this.onChangeDPHandler,
              this.onSaveHandler(),
              "col-md-4",
              { disabled: isNotSpecification },
            )}
            {FormControlsRender(
              "TRAFFIC_TYPE",
              this.state.formControls,
              this.onChangeHandler,
              this.onSaveHandler(),
              "col-md-4",
              { disabled: isNotSpecification },
            )}
            {FormControlsRender(
              "DATE_TRAFFIC_END",
              this.state.formControls,
              this.onChangeDPHandler,
              this.onSaveHandler(),
              "col-md-4",
              { disabled: isNotSpecification },
            )}
          </Row>
          <Row>
            {FormControlsRender(
              "CONTACTS",
              this.state.formControls,
              this.onChangeHandler,
              this.onSaveHandler(),
              "col-md-6",
            )}
            {FormControlsRender(
              "PRIM",
              this.state.formControls,
              this.onChangeHandler,
              this.onSaveHandler(),
              "col-md-6",
            )}
          </Row>

          <Nav
            variant="tabs"
            defaultActiveKey={this.state.navKey}
            onSelect={(key) => this.setState({ navKey: key })}
          >
            <Nav.Item>
              <Nav.Link eventKey="1">Документы</Nav.Link>
            </Nav.Item>
            {isNotSpecification ? null : (
              <>
                <Nav.Item>
                  <Nav.Link eventKey="2">Датчики</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="3">Датчики протечки</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="4">Запорная арматура</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="5">БС и МТ</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="6">Приборы учета</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="7">Опции</Nav.Link>
                </Nav.Item>
              </>
            )}
          </Nav>

          <div className="tab-content tabContent" id="nav-tabContent">
            <span
              style={{ display: this.state.navKey !== "1" ? "none" : null }}
            >
              <Files
                disabled={!this.id}
                mainUrl={`retranslate/${this.props.server}/contract/${this.id}`}
                files={this.state.card?.FILES ?? []}
                onChangeHandler={() =>
                  this.loadAndParentReload(this.id, ["FILES"])
                }
              />
            </span>
            <span
              style={{ display: this.state.navKey !== "2" ? "none" : null }}
            >
              <Tab
                cards={this.state.card?.SENSORS ?? []}
                onChangeHandler={() =>
                  this.loadAndParentReload(this.id, ["SENSORS"])
                }
                renderFunction={(item) => (
                  <Sensor
                    item={item}
                    onClick={this.devModalShow.bind(this, item, "sensor")}
                  />
                )}
                renderSearchFunction={(item) => <Sensor item={item} />}
                addUrl={`retranslate/${this.props.server}/contract-sensor`}
                addDataIn={(item) => ({
                  CONTRACT_ID: this.id,
                  SENSOR_ID: item.ID,
                })}
                deleteUrl={(item) =>
                  `retranslate/${this.props.server}/contract-sensor/${item.ID_LINK}`
                }
                searchUrl={`retranslate/${this.props.server}/sensor`}
                filter={{ noContract: this.id ?? null }}
                groupAddUrl={`retranslate/${this.props.server}/contract-sensor/group-add/${this.id}`}
                disabled={!this.id}
              />
            </span>
            <span
              style={{ display: this.state.navKey !== "3" ? "none" : null }}
            >
              <Tab
                cards={this.state.card?.LEAKAGE_SENSORS ?? []}
                onChangeHandler={() =>
                  this.loadAndParentReload(this.id, ["LEAKAGE_SENSORS"])
                }
                renderFunction={(item) => (
                  <LeakageSensor
                    item={item}
                    onClick={this.devModalShow.bind(
                      this,
                      item,
                      "leakage-sensor",
                    )}
                  />
                )}
                renderSearchFunction={(item) => <LeakageSensor item={item} />}
                addUrl={`retranslate/${this.props.server}/contract-leakage-sensor`}
                addDataIn={(item) => ({
                  CONTRACT_ID: this.id,
                  SENSOR_ID: item.ID,
                })}
                deleteUrl={(item) =>
                  `retranslate/${this.props.server}/contract-leakage-sensor/${item.ID_LINK}`
                }
                searchUrl={`retranslate/${this.props.server}/leakage-sensor`}
                filter={{ noContract: this.id ?? null }}
                groupAddUrl={`retranslate/${this.props.server}/contract-leakage-sensor/group-add/${this.id}`}
                disabled={!this.id}
              />
            </span>
            <span
              style={{ display: this.state.navKey !== "4" ? "none" : null }}
            >
              <Tab
                cards={this.state.card?.VALVES_NBIOT ?? []}
                onChangeHandler={() =>
                  this.loadAndParentReload(this.id, ["VALVES_NBIOT"])
                }
                renderFunction={(item) => (
                  <ValveNbiot
                    item={item}
                    onClick={this.devModalShow.bind(this, item, "valve-nbiot")}
                  />
                )}
                renderSearchFunction={(item) => <ValveNbiot item={item} />}
                addUrl={`retranslate/${this.props.server}/contract-valve-nbiot`}
                addDataIn={(item) => ({
                  CONTRACT_ID: this.id,
                  VALVE_ID: item.ID,
                })}
                deleteUrl={(item) =>
                  `retranslate/${this.props.server}/contract-valve-nbiot/${item.ID_LINK}`
                }
                searchUrl={`retranslate/${this.props.server}/valve-nbiot`}
                filter={{ noContract: this.id ?? null }}
                groupAddUrl={`retranslate/${this.props.server}/contract-valve-nbiot/group-add/${this.id}`}
                disabled={!this.id}
              />
            </span>
            <span
              style={{ display: this.state.navKey !== "5" ? "none" : null }}
            >
              <Tab
                cards={this.state.card?.MODEMS ?? []}
                onChangeHandler={() =>
                  this.loadAndParentReload(this.id, ["MODEMS"])
                }
                renderFunction={(item) => (
                  <Modem
                    item={item}
                    onClick={this.devModalShow.bind(this, item, "modem")}
                  />
                )}
                renderSearchFunction={(item) => <Modem item={item} />}
                addUrl={`retranslate/${this.props.server}/contract-modem`}
                addDataIn={(item) => ({
                  CONTRACT_ID: this.id,
                  MODEM_ID: item.ID,
                })}
                deleteUrl={(item) =>
                  `retranslate/${this.props.server}/contract-modem/${item.ID_LINK}`
                }
                searchUrl={`retranslate/${this.props.server}/modem`}
                filter={{ noContract: this.id ?? null }}
                groupAddUrl={`retranslate/${this.props.server}/contract-modem/group-add/${this.id}`}
                disabled={!this.id}
              />
            </span>
            <span
              style={{ display: this.state.navKey !== "6" ? "none" : null }}
            >
              <Tab
                cards={this.state.card?.COUNTERS ?? []}
                onChangeHandler={() =>
                  this.loadAndParentReload(this.id, ["COUNTERS"])
                }
                renderFunction={(item) => (
                  <Counter
                    item={item}
                    onClick={this.devModalShow.bind(this, item, "counter")}
                  />
                )}
                renderSearchFunction={(item) => <Counter item={item} />}
                addUrl={`retranslate/${this.props.server}/contract-counter`}
                addDataIn={(item) => ({
                  CONTRACT_ID: this.id,
                  COUNTER_ID: item.ID,
                })}
                deleteUrl={(item) =>
                  `retranslate/${this.props.server}/contract-counter/${item.ID_LINK}`
                }
                searchUrl={`retranslate/${this.props.server}/counter`}
                filter={{ noContract: this.id ?? null }}
                groupAddUrl={`retranslate/${this.props.server}/contract-counter/group-add/${this.id}`}
                disabled={!this.id}
              />
            </span>
            <span
              style={{ display: this.state.navKey !== "7" ? "none" : null }}
            >
              <Services
                key={`${this.state.card?.ID ?? "services"}_${this.state.card.SERVICES?.length}`}
                services={this.state.card.SERVICES}
                onChange={(SERVICES) =>
                  this.setState({
                    postData: Object.assign({}, this.state.postData, {
                      SERVICES,
                    }),
                  })
                }
              />
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.id ? (
            <Button
              disabled={this.state.requestProcess}
              variant="danger"
              onClick={this.delClick}
            >
              Удалить
            </Button>
          ) : null}
          <Button
            disabled={this.disabledSaveButtons()}
            variant="primary"
            onClick={this.saveClick}
          >
            Сохранить и продолжить
          </Button>
          <Button
            disabled={this.disabledSaveButtons()}
            variant="primary"
            onClick={this.saveCloseClick}
          >
            Сохранить и закрыть
          </Button>
          <Button
            disabled={this.state.requestProcess}
            variant="secondary"
            onClick={this.cancelClick}
          >
            Отмена
          </Button>
        </Modal.Footer>

        <Modal
          show={this.state.deviceModal.show}
          onHide={this.devModalHide}
          size="xl"
        >
          {this.state.deviceModal.type === "sensor" ? (
            <SensorForm
              server={this.props.server}
              id={this.state.deviceModal.device?.ID}
              hideCallback={this.devModalHide}
              parentReloadCallback={() =>
                this.loadAndParentReload(this.id, ["SENSORS"])
              }
              mainUrl="sensor"
            />
          ) : null}
          {this.state.deviceModal.type === "leakage-sensor" ? (
            <LeakageForm
              server={this.props.server}
              id={this.state.deviceModal.device?.ID}
              hideCallback={this.devModalHide}
              parentReloadCallback={() =>
                this.loadAndParentReload(this.id, ["LEAKAGE_SENSORS"])
              }
              mainUrl="leakage-sensor"
            />
          ) : null}
          {this.state.deviceModal.type === "modem" ? (
            <ModemForm
              server={this.props.server}
              id={this.state.deviceModal.device?.ID}
              hideCallback={this.devModalHide}
              parentReloadCallback={() =>
                this.loadAndParentReload(this.id, ["MODEMS"])
              }
              mainUrl="modem"
            />
          ) : null}
          {this.state.deviceModal.type === "counter" ? (
            <CounterForm
              server={this.props.server}
              id={this.state.deviceModal.device?.ID}
              hideCallback={this.devModalHide}
              parentReloadCallback={() =>
                this.loadAndParentReload(this.id, ["COUNTERS"])
              }
              mainUrl="counter"
            />
          ) : null}
          {this.state.deviceModal.type === "valve-nbiot" ? (
            <ValveNbiotForm
              server={this.props.server}
              id={this.state.deviceModal.device?.ID}
              hideCallback={this.devModalHide}
              parentReloadCallback={() =>
                this.loadAndParentReload(this.id, ["VALVES_NBIOT"])
              }
              mainUrl="valve-nbiot"
            />
          ) : null}
        </Modal>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    );
  }
}
