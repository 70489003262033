import React from "react";
import { arrayObjToText } from "../../../Utils/Helpers";
import BaseItem from "../BaseItem";

export default class ClientsItem extends BaseItem {
  render() {
    return (
      <tr
        onClick={this.onClick.bind(this, this.props.info.ID)}
        style={{ cursor: "pointer" }}
      >
        <th title={this.props.info.ID}>{this.props.info.TITLE}</th>

        <td>{this.props.info.EMAIL}</td>

        <td>
          <div>{arrayObjToText(this.props.info.LS, "ADDR_TITLE", 2000)}</div>
        </td>

        <td>
          <div>
            <i
              className="fas fa-fw fa-clipboard"
              title="Скопировать GUID"
              onClick={this.copyCB.bind(this, this.props.info.ID)}
            >
              {" "}
            </i>
            {this.props.info.COMMENT ? (
              <i
                className="fas fa-fw fa-comment-dots"
                title={this.props.info.COMMENT}
              >
                {" "}
              </i>
            ) : (
              <i className="fas fa-fw"> </i>
            )}
          </div>
        </td>
      </tr>
    );
  }
}
