import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { RequestWithToken } from "../../Components/General/Request";
import { useParams } from "react-router-dom";

import Abonents from "../inServer/abonents/Abonents";
import Acceleration_sensors from "../inServer/acceleration_sensors/AccelerationSensors";
import Accounts from "../inServer/accounts/Accounts";
import Balance_groups from "../inServer/balance_groups/BalanceGroups";
import Clients from "../inServer/clients/Clients";
import Contracts from "../inServer/contracts/Contracts";
import Contractors from "../inServer/contractors/Contractors";
import Counters from "../inServer/counters/Counters";
import Guard_sensors from "../inServer/guard_sensors/GuardSensors";
import Leakages from "../inServer/leakages/Leakages";
import Magnetic_contact_sensors from "../inServer/magnetic_contact_sensors/MagneticContactSensors";
import Modems from "../inServer/modems/Modems";
import Organizations from "../inServer/organizations/Organizations";
import Plans from "../inServer/plans/Plans";
import Schemes_mkd from "../inServer/schemes_mkd/SchemesMkd";
import Sensors from "../inServer/sensors/Sensors";
import Telecom_ls from "../inServer/telecom_ls/TelecomLs";
import Valves from "../inServer/valves/Valves";
import Valves_nbiot from "../inServer/valves_nbiot/ValvesNbiot";
import Weather_sources from "../inServer/weather_sources/WeatherSources";
import Zones from "../inServer/zones/Zones";

import EnotFound from "../Errors/EnotFound";
import { userCan } from "../../Utils/Helpers";
import Points from "../inServer/points/Points";

export default function Server(props) {
  const params = useParams();

  const [state, setState] = useState({
    errorConnect: false,
    cards: [],
    totalCount: 0,
    count: 0,
    curServer: { id: params.server ?? null },
    curModule: { action: params.act ?? null },
    modules: [],
    curServerPermissions: {},
  });

  const moduleComponents = {
    Abonents,
    Acceleration_sensors,
    Accounts,
    Balance_groups,
    Clients,
    Contracts,
    Contractors,
    Counters,
    Guard_sensors,
    Leakages,
    Magnetic_contact_sensors,
    Modems,
    Organizations,
    Plans,
    Schemes_mkd,
    Sensors,
    Telecom_ls,
    Valves,
    Valves_nbiot,
    Weather_sources,
    Zones,
    Points,
  };

  async function loadList(searchText = "") {
    setState({ ...state, errorConnect: false });

    RequestWithToken("server/user-server-list")
      .get({
        searchMode: 0,
        start: 0,
        limit: 50,
        needTotalCount: 1,
        name: searchText,
      })
      .then((data) => {
        setState({
          ...state,
          cards: data.items,
          totalCount: data.total_count,
          count: data.count,
        });
      })
      .catch(() => {
        setState({
          ...state,
          errorConnect: true,
          cards: [],
          totalCount: 0,
          count: 0,
        });
      });
  }

  function navigateTo(
    server = state.curServer.id,
    action = state.curModule?.action,
  ) {
    const curServer = searchServerById(server);
    const curServerPermissions = curServer?.serverPermissions;

    let modules = curServer.modules;

    if (!userCan("server", "all")) {
      modules = curServer.modules.filter((item) => {
        const mainUrl = moduleComponents[item.action].mainUrl;
        return curServerPermissions[mainUrl];
      });
    }

    const curModule = modules.find((o) => o.action === action) ?? modules[0];

    setState({ ...state, curServer, curModule, modules, curServerPermissions });

    window.history.pushState(
      {},
      undefined,
      `/server/${curServer.id}/${curModule?.action}`,
    );
  }

  function searchServerById(id) {
    let serverA = {};

    for (let cards_v in state.cards) {
      if (state.cards[cards_v].id === id) {
        serverA = state.cards[cards_v];
      }
    }

    return serverA;
  }

  useEffect(() => {
    loadList(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.cards.length) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      navigateTo();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.cards]);

  function CurrentModule() {
    const Mod = moduleComponents[state.curModule.action] ?? EnotFound;
    return (
      <Mod
        server={state.curServer.id}
        serverPermissions={state.curServerPermissions}
      />
    );
  }

  return (
    <>
      <h1 className={"d-none d-sm-block"}>Работа с сервером </h1>
      <nav aria-label="breadcrumb" className="navInSrv">
        <ol className="breadcrumb">
          <Dropdown className={"align-self-center"}>
            <Dropdown.Toggle
              variant="Secondary"
              style={{ padding: 0, color: "#777" }}
            >
              {state.curServer.name ?? ""}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {state.cards.map((server) => {
                const title =
                  server.id === state.curServer.id ? (
                    <b>{server.name}</b>
                  ) : (
                    server.name
                  );
                return (
                  <Dropdown.Item
                    key={server.id}
                    onClick={navigateTo.bind(
                      this,
                      server.id,
                      state.curModule?.action,
                    )}
                  >
                    {title}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            className={"align-self-center"}
            style={{ display: "inline-block" }}
          >
            <Dropdown.Toggle
              variant="Secondary"
              style={{ padding: 0, color: "#777" }}
            >
              &nbsp;/&nbsp;{state.curModule?.name ?? ""}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {state.modules.map((module) => {
                const title =
                  module.action === state.curModule?.action ? (
                    <b>{module.name}</b>
                  ) : (
                    module.name
                  );

                return (
                  <Dropdown.Item
                    key={module.action}
                    onClick={navigateTo.bind(
                      this,
                      state.curServer.id,
                      module.action,
                    )}
                  >
                    {title}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </ol>
      </nav>

      {state.modules.length && state.curServer.id && state.curModule?.action ? (
        <CurrentModule />
      ) : null}
    </>
  );
}
