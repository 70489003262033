import BaseForm from "../inServer/BaseForm";

import React from "react";
import { Button, Form, Modal, Row, Nav } from "react-bootstrap";
import ConfirmModal from "../../Components/General/ConfirmModal";

import FormControlsRender from "../../Utils/FormControlsRender";
import {
  Select,
  Password,
  Text,
  Checkbox,
} from "../../Utils/FormControlsPresets";
import Guid from "../../Components/Guid";
import { userCan, userCanOr } from "../../Utils/Helpers";

export default class UserForm extends BaseForm {
  idField = "id"; //в weather он например CITY_ID

  constructor(props) {
    super(props);

    const perm = [
      [0, "Запрещен"],
      [1, "Чтение"],
      [15, "Полный"],
    ];

    this.state = {
      navKey: "1",
      requestProcess: false,
      formControls: {
        username: Text("Логин"),
        title: Text("ФИО"),
        email: Text("Email"),
        status: Select("Статус", [
          [10, "Активирован"],
          [9, "Заблокирован"],
        ]),
        server: Select('Справочник "Серверы"', perm),
        user: Select('Справочник "Пользователи"', perm),
        permission: Select('Справочник "Разрешения"', perm),
        module: Select('Справочник "Модули"', perm),
        password: Password(
          "Пароль",
          "заполните поле, если необходимо сменить пароль",
        ),
        show_always_pers_data: Checkbox("Отображать перс. данные"),
      },
      postData: {},
    };

    this.id = props.id; //для обновления ID
  }

  afterSave = (response) => {
    this.setState({ postData: {} });

    if (this.props.parentSavedCallback) {
      if (!this.id) {
        this.props.parentSavedCallback(response.ID);
      } else {
        this.props.parentSavedCallback();
      }
    }

    const userName = this.state.formControls.title.value;
    if (localStorage.id === response.id && localStorage.title !== userName) {
      localStorage.title = userName;
      setTimeout(() => {
        window.location.href = "#";
      }, 500);
    }
  };

  afterLoad = (items) => {
    const postData = this.state.postData;
    const formControls = this.getFormControls(items);
    formControls.server.value =
      postData.server ?? items?.permissions?.server ?? 0;
    formControls.user.value = postData.user ?? items?.permissions?.user ?? 0;
    formControls.permission.value =
      postData.permission ?? items?.permissions?.permission ?? 0;
    formControls.module.value =
      postData.module ?? items?.permissions?.module ?? 0;

    this.setState({
      ...items,
      thisLs: items.LS ? items.LS.ID : null,
      typeSchet: items.TYPE_SCHET,
      formControls,
    });
  };

  getDateIn() {
    const permNames = ["server", "user", "permission", "module"];

    let postData = this.state.postData;
    postData.permissions = this.state.postData.permissions ?? {};

    permNames.forEach((element) => {
      if (postData[element] !== undefined) {
        postData.permissions[element] = postData[element];
        delete postData[element];
      }
    });

    return postData;
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{`${this.id ? "Редактирование" : "Создание"} пользователя`}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={
            userCanOr(this.props.mainUrl, ["update", "create"])
              ? null
              : "disabled"
          }
        >
          <Form>
            <Row>
              <Guid key={this.id} value={this.id} />
            </Row>
            <Row>
              {FormControlsRender(
                "title",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-12",
              )}
            </Row>
            <Row>
              {FormControlsRender(
                "email",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-4",
              )}
              {FormControlsRender(
                "status",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-4",
              )}
              {FormControlsRender(
                "show_always_pers_data",
                this.state.formControls,
                this.onChangeCheckboxHandler,
                this.onSaveHandler(),
                "col-md-4",
              )}
            </Row>
            <Row>
              {FormControlsRender(
                "username",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-6",
              )}
              {FormControlsRender(
                "password",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-6",
              )}
            </Row>
          </Form>

          <div style={{ height: "15px" }}></div>

          <Nav
            variant="tabs"
            defaultActiveKey={this.state.navKey}
            onSelect={(key) => this.setState({ navKey: key })}
          >
            <Nav.Item>
              <Nav.Link eventKey="1">Управление разрешениями</Nav.Link>
            </Nav.Item>
          </Nav>

          <div className="tab-content tabContent" id="nav-tabContent">
            <span
              style={{ display: this.state.navKey !== "1" ? "none" : null }}
            >
              <Row>
                {FormControlsRender(
                  "server",
                  this.state.formControls,
                  this.onChangeHandler,
                  this.onSaveHandler(),
                  "col-md-6",
                )}
                {FormControlsRender(
                  "user",
                  this.state.formControls,
                  this.onChangeHandler,
                  this.onSaveHandler(),
                  "col-md-6",
                )}
              </Row>
              <Row>
                {FormControlsRender(
                  "permission",
                  this.state.formControls,
                  this.onChangeHandler,
                  this.onSaveHandler(),
                  "col-md-6",
                )}
                {FormControlsRender(
                  "module",
                  this.state.formControls,
                  this.onChangeHandler,
                  this.onSaveHandler(),
                  "col-md-6",
                )}
              </Row>
            </span>

            {this.id ? null : (
              <div className="splash-block">
                {" "}
                Для работы с этим блоком нажмите "Сохранить и продолжить"
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.id && userCan("user", "delete") ? (
            <Button
              disabled={this.state.requestProcess}
              variant="danger"
              onClick={this.delClick}
            >
              Удалить
            </Button>
          ) : null}
          {userCan(this.props.mainUrl, this.id ? "update" : "create") ? (
            <>
              <Button
                disabled={this.disabledSaveButtons()}
                variant="primary"
                onClick={this.saveClick}
              >
                Сохранить и продолжить
              </Button>
              <Button
                disabled={this.disabledSaveButtons()}
                variant="primary"
                onClick={this.saveCloseClick}
              >
                Сохранить и закрыть
              </Button>
            </>
          ) : null}
          <Button
            disabled={this.state.requestProcess}
            variant="secondary"
            data-dismiss="modal"
            onClick={this.props.hideCallback}
          >
            Отмена
          </Button>
        </Modal.Footer>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    );
  }
}
