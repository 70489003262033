import conf from "../../Config";
import axios from "axios";
import { toast } from "react-toastify";

export function RequestWithToken(path) {
  return Request(path).addTokenFromLocalStorage();
}

export function Request(path) {
  var params = {};
  var token = null;
  var responseType = RESPONSE_TYPE_JSON;
  var data = {};
  var headers = {};
  var cancelToken = null;

  function addTokenFromLocalStorage() {
    token = localStorage.token;
    return this;
  }

  function setCancelToken(newCancelToken = CANCEL) {
    cancelToken = newCancelToken;
    return this;
  }

  function setData(newData) {
    data = newData;
    return this;
  }

  function setHeaders(newHeaders) {
    headers = newHeaders;
    return this;
  }

  function setResponseType(newResponseType) {
    responseType = newResponseType;
    return this;
  }

  function get(newParams = {}) {
    params = newParams;
    return fetch(METHOD_GET);
  }

  function getFile(newParams = {}) {
    params = newParams;
    responseType = RESPONSE_TYPE_BLOB;
    return fetch(METHOD_GET);
  }

  function post(newData = {}) {
    data = newData;
    return fetch(METHOD_POST);
  }

  function put(newData = {}) {
    data = newData;
    return fetch(METHOD_PUT);
  }

  function del(newData = {}) {
    data = newData;
    return fetch(METHOD_DELETE);
  }

  async function fetch(method) {
    document.getElementById("loader").style.visibility = "visible";

    try {
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      const response = await axios({
        method,
        url: conf.api_url + path,
        headers,
        data,
        params,
        cancelToken,
        responseType,
      });

      if (
        path !== "auth" &&
        response?.data?.permissions &&
        localStorage.permissions !== JSON.stringify(response.data.permissions)
      ) {
        localStorage.permissions = JSON.stringify(response.data.permissions);
        toast.warning("Изменились разрешения. Страница будет перезагружена");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }

      if (
        responseType === RESPONSE_TYPE_BLOB &&
        response.data?.type !== "application/json"
      ) {
        return response.data;
      }

      if (response.data.success || response.data.status === "ok") {
        return response.data;
      }

      throw new NotSuccessfullResponseError(response);
    } catch (e) {
      if (e.message === CANCEL) {
        throw e;
      }

      switch (e.response?.status) {
        case undefined:
          toast.error(
            "Сервер недоступен или отсутствует интернет соединение",
            "Ошибка сети",
            2000,
          );
          break;
        case 200:
          if (e.response?.data?.errors?.message) {
            toast.error(e.response.data.errors.message, "Ошибка", 2000);
          }
          break;
        case 500:
          toast.error("Внутренняя ошибка сервера", "500", 2000);
          break;
        case 401:
          toast.error(
            "Сессия завершена. Для продолжения необходимо авторизоваться.",
            "401",
            2000,
          );
          setTimeout(() => {
            window.location.href = "/logout";
          }, 2000);
          break;
        default:
          toast.error("Ошибка сервера", e.response.status, 2000);
      }

      throw e;
    } finally {
      document.getElementById("loader").style.visibility = "hidden";
    }
  }

  return Object.freeze({
    addTokenFromLocalStorage,
    setCancelToken,
    setData,
    setHeaders,
    setResponseType,
    get,
    getFile,
    post,
    put,
    del,
    fetch,
  });
}

export const METHOD_GET = "get";
export const METHOD_POST = "post";
export const METHOD_PUT = "put";
export const METHOD_DELETE = "delete";

export const RESPONSE_TYPE_JSON = "json";
export const RESPONSE_TYPE_BLOB = "blob";

export const CANCEL = "API is canceled";

class NotSuccessfullResponseError extends Error {
  constructor(response) {
    super("Not succesfull response error");
    this.name = "NotSuccessfullResponseError";
    this.response = response;
  }
}
