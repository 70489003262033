import React from "react";
import FileIcon from "./FileIcon";
import defaultStyles from "./defaultStyles";

export function DefaultFileIcon(props) {
  const ext = props.name.split(".").pop();
  return <FileIcon extension={ext} {...defaultStyles[ext]} />;
}

export { default as FileIcon } from "./FileIcon";
export { default as defaultStyles } from "./defaultStyles";
