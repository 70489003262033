import React from "react";
import BaseItem from "../BaseItem";
import { arrayObjToText } from "../../../Utils/Helpers";

export default class CountersItem extends BaseItem {
  type_str = this.props.info.TYPE_SCHET_RES_NAME;
  counterIcon = (icon) => {
    var fa = "fa-question";
    this.type_str = this.props.info.TYPE_SCHET_RES_NAME;
    switch (icon) {
      case "electro":
        fa = "fas fa-fw res20 res-electro";
        break;
      case "stok":
        fa = "fas fa-fw fa-tint-slash";
        break;
      case "w-cold":
        fa = "fas fa-fw res20 res-w-cold";
        break;
      case "w-hot":
        fa = "fas fa-fw res20 res-w-hot";
        break;
      case "gaz":
        fa = "fas fa-fw res20 res-gaz";
        break;
      case "colorimetr":
        fa = "fas fa-fw res20 res-colorimetr";
        break;
      default:
        fa = "fas fa-fw fa-question";
        this.type_str = "Без типа";
    }
    return fa;
  };

  render() {
    return (
      <tr
        onClick={this.onClick.bind(this, this.props.info.ID)}
        style={
          this.props.info.STOP_TIME
            ? { color: "gray", cursor: "pointer" }
            : { cursor: "pointer" }
        }
      >
        <th title={this.props.info.ID}>
          {this.props.info.IS_BALANS ? (
            <i
              className="fas fa-fw fa-balance-scale text-dark"
              title="Балансовый"
            >
              {" "}
            </i>
          ) : (
            <i className="fas fa-fw"> </i>
          )}
          &nbsp;
          <i
            className={
              "" + this.counterIcon(this.props.info.TYPE_SCHET_ICON_NAME)
            }
            style={{ color: this.props.info.TYPE_SCHET_COLOR }}
            title={this.type_str}
          >
            {" "}
          </i>{" "}
          {this.props.info.CODE}
        </th>

        <td>
          <small
            style={{ whiteSpace: "nowrap", overflow: "hidden", width: "50px" }}
          >
            {this.props.info.CLASS_COUNTER_TITLE}
          </small>
        </td>

        <td title={arrayObjToText(this.props.info.ZONES, "TITLE", 250)}>
          <small>
            {this.props.info.ZONES
              ? this.props.info.ZONES.length + " : "
              : null}{" "}
            {arrayObjToText(this.props.info.ZONES, "TITLE", 25)}
          </small>
        </td>

        <td title={arrayObjToText(this.props.info.MODEMS, "SERNUMBER", 250)}>
          <small>
            {this.props.info.MODEMS
              ? this.props.info.MODEMS.length + " : "
              : null}{" "}
            {arrayObjToText(this.props.info.MODEMS, "SERNUMBER", 25)}
          </small>
        </td>

        <td>
          <div>
            <i
              className="fas fa-fw fa-clipboard"
              title="Скопировать GUID"
              onClick={this.copyCB.bind(this, this.props.info.ID)}
            >
              {" "}
            </i>
            <i
              className="fas fa-fw fa-clipboard-list"
              title="Скопировать CODE"
              onClick={this.copyCB.bind(this, this.props.info.CODE)}
            >
              {" "}
            </i>
            {this.props.info.LS ? (
              <i
                className="fas fa-fw fa-file-invoice"
                title={"Скопировать ЛС: " + this.props.info.LS.TITLE}
                onClick={this.copyCB.bind(this, this.props.info.LS.TITLE)}
              >
                {" "}
              </i>
            ) : (
              <i className="fas fa-fw"> </i>
            )}
            {this.props.info.STOP_TIME ? (
              <i className="fas fa-fw fa-lock text-danger" title="Неактивный">
                {" "}
              </i>
            ) : (
              <i className="fas fa-fw"> </i>
            )}
            {this.props.info.COMMENT ? (
              <i
                className="fas fa-fw fa-comment-dots"
                title={this.props.info.COMMENT}
              >
                {" "}
              </i>
            ) : (
              <i className="fas fa-fw"> </i>
            )}
          </div>
        </td>
      </tr>
    );
  }
}
