import React from "react";
import { Button } from "react-bootstrap";
export default function AddBtn(props) {
  return (
    <Button
      variant="success"
      className="rounded-circle btnAdd"
      title="Новая запись"
      onClick={props.onClick}
    >
      <i className="fas fa-plus-circle"></i>
    </Button>
  );
}
