import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import { RequestWithToken } from "../Request";
import { getObjHashCode } from "../../../Utils/Helpers";
import styles from "./searchInputObj.module.css";

export const MODE_NOT_STRONG = 0;
export const MODE_STRONG = 1;
export const MODE_SMART = 2;

/**
 * Универсальное поле поиска с ниспадающим списком
 */
export default function SearchInputObj(props) {
  const searchMode = props.searchMode ?? MODE_NOT_STRONG;

  const [searchText, setSearchText] = useState(props.defaultItem?.TITLE ?? "");
  const [item, setItem] = useState(props.defaultItem ?? {});
  const [items, setItems] = useState([]);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [errorConnect, setErrorConnect] = useState(false);

  const isNotDebouncingLoad = useRef(true);

  useEffect(() => {
    setItem(props?.defaultItem ?? {});
    setSearchText(props.defaultItem?.TITLE ?? "");
    isNotDebouncingLoad.current = true;
    // eslint-disable-next-line
  }, [getObjHashCode(props.defaultItem ?? {})]);

  const load = async (search = null) => {
    setItems([]);
    setErrorConnect(false);

    RequestWithToken(
      props.server
        ? `retranslate/${props.server}/${props.source}`
        : props.source,
    )
      .get({
        limit: props.limit ?? 20,
        search: (search ?? searchText ?? "").trim(),
        searchMode,
        filter: props.filter ? JSON.stringify(props.filter) : null,
      })
      .then((data) => {
        isNotDebouncingLoad.current = true;
        setItems(data.items);
      })
      .catch(() => {
        setSearchText("");
        setErrorConnect(true);
        setItems([]);
        setItem({});
        return;
      });
  };

  const clearSearch = () => {
    setSearchText("");
    setShowSearchInput(false);
    setItems([]);
    setItem({});

    props.onSelectItem({});
  };

  const searchInput = (e) => setSearchText(e.target.value);

  useEffect(() => {
    if (isNotDebouncingLoad.current) {
      isNotDebouncingLoad.current = false;
      return;
    }

    const timer = setTimeout(() => {
      load(searchText);
    }, 500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [searchText]);

  const searchInputKeyUp = (event) => {
    if (event.keyCode === 13) {
      load(event.target.value);
    }

    if (event.keyCode === 40) {
      // Стрелка вниз, переходим к позициям
    }
  };

  const keyUpItem = (item) => (event) => {
    if (event.keyCode === 13) {
      onSelectItem(item);
    }
  };

  const onSelectItem = (item) => {
    setSearchText(item.TITLE);
    setShowSearchInput(false);
    setItems([]);
    setItem(item);

    props.onSelectItem(item);
  };

  const itemRender = (item) =>
    props.render ? props.render(item) : <>{item.TITLE}</>;

  const searchDropDownMenu = () => {
    if (items.length === 0) {
      return [];
    }

    return (
      <div
        className={styles.searchInputObjRes}
        style={{ ...(props.style ?? null) }}
      >
        {items.map((item) => {
          return (
            <div
              key={item[props.fieldKey ?? "ID"]}
              className={styles.searchInputObjResPointer}
              tabIndex={0}
              onKeyUp={keyUpItem(item)}
              onClick={() => onSelectItem(item)}
            >
              {itemRender(item)}
            </div>
          );
        })}
      </div>
    );
  };

  const keyUp = (e) => {
    if (e.keyCode === 13) {
      searchClick();
    }
  };

  const searchClick = () => {
    setShowSearchInput(true);
    load(searchText);
  };

  return (
    <Form.Group
      className={`search-input-obj ${styles.searchInputObj} col-md-${props.size ?? 4} ${props.disabled ? "disabled" : ""}`}
    >
      <label className={styles.searchInputObjLabel}>{props.label}</label>
      {showSearchInput ? (
        <>
          <div className={`${styles.searchInputObjInputGroup} input-group`}>
            <input
              autoFocus
              type="text"
              name={props.name}
              placeholder="Введите значение для поиска"
              className="form-control"
              value={searchText ?? item.TITLE}
              onChange={searchInput}
              onKeyUp={searchInputKeyUp}
              autoComplete="off"
            />
            <div className="input-group-append">
              <button
                className="btn btn-default"
                type="button"
                onClick={clearSearch}
                title="Очистить"
              >
                <i className="fas fa-fw fa-times"> </i>
              </button>
            </div>
          </div>
          {searchDropDownMenu()}
        </>
      ) : (
        <div
          className={`form-control ${props.errorMessage ? "is-invalid" : ""} ${props.disabled ? "disabled" : ""}`}
          onClick={searchClick}
          onKeyUp={keyUp}
          tabIndex={0}
        >
          {itemRender(item)}
        </div>
      )}
      {errorConnect ? (
        <div className="in-validate">Ошибка запроса к справочнику</div>
      ) : null}
      <Form.Control.Feedback type="invalid">
        {props.errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
