import React from "react";
import { Button, Form, Row } from "react-bootstrap";
import { RequestWithToken } from "../../Components/General/Request";
import { toast } from "react-toastify";
import { Navbar } from "react-bootstrap";
import ConfirmModal, {
  hideConfirmModal,
} from "../../Components/General/ConfirmModal";

export default class Secret extends React.Component {
  loadTimer = null;
  //server = 'ee7d696d-3949-4a71-a1c2-b3c55ad04758'
  server = "a605251d-d6d0-46c6-8d1e-cda11be07075";

  state = {
    selectedCounter: "ef325410-9feb-40da-b7d1-9a2e41e7ee38",
    b0: "secondary",
    b1: "secondary",
    b2: "secondary",
    b3: "secondary",
    b4: "secondary",
    b5: "secondary",
    b6: "secondary",
    b7: "secondary",
    b8: "secondary",
    b9: "secondary",
    b10: "secondary",
    b11: "secondary",
    b12: "secondary",
    b13: "secondary",
    b14: "secondary",
    b15: "secondary",
    b16: "secondary",
    b17: "secondary",
  };

  async load() {
    clearTimeout(this.loadTimer);
    this.setState({ requestProcess: true });

    RequestWithToken(
      `retranslate/${this.server}/secret/${this.state.selectedCounter}`,
    )
      .get()
      .then((data) => {
        if (!data?.items) {
          toast.error("Объект не найден");
        } else {
          this.afterLoad(data.items);
        }
      })
      .catch(() => {
        toast.error("Ошибка запроса");
      });

    this.setState({ requestProcess: false });
    this.loadTimer = setTimeout(() => this.load(), 10000);
  }

  afterLoad(response) {
    const alertState = response.ALERT_STATE;

    this.setState({
      b0: this.getButtonState(alertState, 0),
      b1: this.getButtonState(alertState, 1),
      b2: this.getButtonState(alertState, 2),
      b3: this.getButtonState(alertState, 3),
      b4: this.getButtonState(alertState, 4),
      b5: this.getButtonState(alertState, 5),
      b6: this.getButtonState(alertState, 6),
      b7: this.getButtonState(alertState, 7),
      b8: this.getButtonState(alertState, 8),
      b9: this.getButtonState(alertState, 9),
      b10: this.getButtonState(alertState, 10),
      b11: this.getButtonState(alertState, 11),
      b12: this.getButtonState(alertState, 12),
      b13: this.getButtonState(alertState, 13),
      b14: this.getButtonState(alertState, 14),
      b15: this.getButtonState(alertState, 15),
      b16: this.getButtonState(alertState, 16),
      b17: this.getButtonState(alertState, 17),
    });
  }

  async save(bit) {
    this.setState({ requestProcess: true });

    RequestWithToken(
      `retranslate/${this.server}/secret/${this.state.selectedCounter}`,
    )
      .put({ bit })
      .then((data) => {
        if (data.items) {
          this.afterLoad(data.items);
        }
      })
      .catch(() => {
        toast.error("Ошибка сохранения");
      });

    this.setState({ requestProcess: false });
  }

  getNotificationErrors(errors) {
    let notifErrors = [];

    if (typeof errors === "string") {
      notifErrors.push(errors);
    } else if (Array.isArray(errors) || typeof errors === "object") {
      for (const [key, value] of Object.entries(errors)) {
        if (!this.state.formControls[key]) {
          notifErrors.push(typeof value === "string" ? value : [...value]);
        }
      }
    }

    return notifErrors;
  }

  getButtonState(state, bit) {
    return state & (1 << bit) ? "success" : "danger";
  }

  onChange(e) {
    this.setState({ selectedCounter: e.target.value }, this.load);
  }

  componentDidMount() {
    this.load(this.id);
  }

  componentWillUnmount() {
    clearTimeout(this.loadTimer);
  }

  buttonStyle() {
    return {
      width: "50%",
    };
  }

  logout() {
    this.setState({
      confirmModal: {
        text: "Вы действительно хотите выйти из системы?",
        handleClose: hideConfirmModal.bind(this),
        handleAccept: hideConfirmModal.bind(this, () => {
          localStorage.isLoginIn = false;
          localStorage.token = "";
          localStorage.title = "";
          localStorage.id = "";
          window.location.href = "/login";
        }),
      },
    });
  }

  render() {
    return (
      <>
        <Navbar bg="light" className="nav-bar">
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <span className="userName">
                {localStorage.title}
                &nbsp;&nbsp;&nbsp;
                <span
                  to="/logout"
                  style={{ cursor: "pointer" }}
                  onClick={this.logout.bind(this)}
                  title="Выход из системы"
                >
                  <i className="fas fa-sign-out-alt"> </i>
                </span>
              </span>
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
        <Form style={{ marginTop: "70px", position: "relative" }}>
          <Row>
            <select
              className="form-control"
              value={this.state.selectedCounter}
              onChange={this.onChange.bind(this)}
            >
              <option value="870e6dcb-bc08-4112-9b8e-7cf72ffdf161">
                007009
              </option>
              <option value="ef325410-9feb-40da-b7d1-9a2e41e7ee38">
                014570
              </option>
              <option value="184a550b-9af5-47ac-a028-379cf8cba701">
                001871
              </option>
            </select>
          </Row>
          <br />
          <Row>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b0}
              onClick={this.save.bind(this, 0)}
            >
              Ошибки измерений
            </Button>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b1}
              onClick={this.save.bind(this, 1)}
            >
              Ошибки температуры
            </Button>
          </Row>
          <Row>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b2}
              onClick={this.save.bind(this, 2)}
            >
              Ошибки контроля
            </Button>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b3}
              onClick={this.save.bind(this, 3)}
            >
              Ошибки калибр.
            </Button>
          </Row>
          <Row>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b4}
              onClick={this.save.bind(this, 4)}
            >
              Ошибки железа
            </Button>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b5}
              onClick={this.save.bind(this, 5)}
            >
              Низкий уровень заряда
            </Button>
          </Row>
          <Row>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b6}
              onClick={this.save.bind(this, 6)}
            >
              Переповтор связи с сервером
            </Button>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b7}
              onClick={this.save.bind(this, 7)}
            >
              Критичный уровень связи
            </Button>
          </Row>
          <Row>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b8}
              onClick={this.save.bind(this, 8)}
            >
              Вскрытие корпуса
            </Button>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b9}
              onClick={this.save.bind(this, 9)}
            >
              Внешний магнит
            </Button>
          </Row>
          <Row>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b10}
              onClick={this.save.bind(this, 10)}
            >
              Отсутствие расхода
            </Button>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b11}
              onClick={this.save.bind(this, 11)}
            >
              Ошибка LCD
            </Button>
          </Row>
          <Row>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b12}
              onClick={this.save.bind(this, 12)}
            >
              Технологический режим
            </Button>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b13}
              onClick={this.save.bind(this, 13)}
            >
              Связь со счётчиком устан.
            </Button>
          </Row>
          <Row>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b14}
              onClick={this.save.bind(this, 14)}
            >
              Основной сервер
            </Button>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b15}
              onClick={this.save.bind(this, 15)}
            >
              Перезагрузка по питанию
            </Button>
          </Row>
          <Row>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b16}
              onClick={this.save.bind(this, 16)}
            >
              Перезагрузка по RESET
            </Button>
            <Button
              style={this.buttonStyle()}
              variant={this.state.b17}
              onClick={this.save.bind(this, 17)}
            >
              Выход на связь по подкл. счётч.
            </Button>
          </Row>
        </Form>
        <ConfirmModal {...this.state.confirmModal} />
      </>
    );
  }
}
