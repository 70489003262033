import React from "react";

const Guide = (props) => {
  return (
    <>
      <h1 className={"d-none d-sm-block"}>Руководство по эксплуатации</h1>
      <div className="contentInner">
        <iframe
          title="rukovod"
          src="./upload/manual_pud_kvant.pdf?230421"
          className="guide_frame"
        ></iframe>
      </div>
    </>
  );
};

export default Guide;
