import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

export function arrayObjToText(arr, prop = "TITLE", leg = 250) {
  let text = "";
  let text_o;
  for (const text_v in arr) {
    if (arr[text_v][prop]) {
      text = text + ", " + arr[text_v][prop];
    }
  }

  text_o = text.substr(2, leg);
  if (text.length > leg) {
    text_o += "...";
  }
  return text_o;
}

export function showNotificationErrors(
  mainMessage = null,
  response = null,
  formControls = [],
) {
  const errors = response?.data?.errors ?? [];

  let notifErrors = [];

  if (mainMessage) {
    toast.error(mainMessage);
  }

  if (typeof errors === "string") {
    notifErrors.push(errors);
  } else if (Array.isArray(errors) || typeof errors === "object") {
    for (const [key, value] of Object.entries(errors)) {
      if (typeof value === "string") {
        notifErrors.push(value);
      } else if (Array.isArray(value) || typeof value === "object") {
        if (!formControls[key]) {
          notifErrors.push(...value);
        }
      }
    }
  }

  notifErrors.forEach(function (error) {
    toast.warning(error);
  });

  return notifErrors;
}

export function userCan(name, action = null) {
  const actionValues = {
    read: 1,
    create: 2,
    update: 4,
    delete: 8,
    all: 15,
  };

  const actionValue = actionValues[action] ?? 15;

  const userPermissions = JSON.parse(localStorage.getItem("permissions") ?? []);
  const userPermission = userPermissions[name] ?? 0;

  if (action === null) {
    return userPermission ? true : false;
  }

  return userPermission
    ? (userPermission & actionValue) === actionValue
    : false;
}

export function userCanOr(name, actions) {
  const even = (action) => userCan(name, action);
  return actions.some(even);
}

export function userCanAnd(name, actions) {
  const even = (action) => userCan(name, action);
  return actions.every(even);
}

export function getMimeByExt(str) {
  const ext = str.split(".").pop();

  const mimes = {
    pdf: "application/pdf",
    jpg: "image/jpeg",
    png: "image/png",
    svg: "image/svg+xml",
  };
  return mimes[ext];
}

export function getObjHashCode(obj) {
  if (obj === undefined) {
    return uuidv4();
  }

  const str = JSON.stringify(obj) ?? "";
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    let character = str.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
