import React, { useState } from "react";

import { Form, Row } from "react-bootstrap";

export default function Services(props) {
  const [services, setServices] = useState(props.services ?? []);

  const checkBoxClick = (e) => {
    const serviceName = e.target.name;
    let filteredServices = services.filter((item) => item !== serviceName);

    if (e.target.checked) {
      filteredServices.push(serviceName);
    }

    setServices(filteredServices);
    props.onChange(filteredServices);
  };

  const Service = (props) => (
    <Form.Label className={`col-md-${props.size}`}>
      <input
        name={props.name}
        type="checkbox"
        onChange={checkBoxClick}
        checked={services.includes(props.name) ?? false}
      />{" "}
      {props.label}
    </Form.Label>
  );

  return (
    <>
      <Row>
        {/* <Service label="Уведомления в Telegram" name="telegram" size={4} /> */}
        <Service label="Голосовые уведомления" name="voice" size={4} />
        <Service label="Cмс уведомления" name="sms" size={4} />
      </Row>
      <Row>
        <Service label="Картографический сервис" name="map" size={4} />
        <Service label="Геолокации LBS" name="lbs" size={4} />
      </Row>
    </>
  );
}
