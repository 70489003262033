import React from "react";

export default function Counter(props) {
  const item = props.item;

  const title = item.COUNTER_CODE ?? item.CODE;
  const isBalance = item.COUNTER_IS_BALANS ?? item.IS_BALANS;
  const resTitle = item.COUNTER_TYPE_RES_NAME ?? item.TYPE_SCHET_RES_NAME;
  const resIcon = item.COUNTER_TYPE_ICON ?? item.TYPE_SCHET_ICON_NAME;
  const resColor = item.TYPE_SCHET_COLOR;
  const classTitle = item.CLASS_COUNTER_TITLE ?? item.COUNTER_MODEL_TITLE;

  const getIcon = function (icon) {
    switch (icon) {
      case "electro":
        return "fas fa-fw res20 res-electro";
      case "stok":
        return "fas fa-fw fa-tint-slash";
      case "w-cold":
        return "fas fa-fw res20 res-w-cold";
      case "w-hot":
        return "fas fa-fw res20 res-w-hot";
      case "gaz":
        return "fas fa-fw res20 res-gaz";
      case "colorimetr":
        return "fas fa-fw res20 res-colorimetr";
      case undefined:
        return null;
      default:
        return "fas fa-fw fa-question";
    }
  };

  return (
    <span
      onClick={props.onClick ?? null}
      style={props.onClick ? { cursor: "pointer" } : null}
      title={props.onClick ? "Просмотреть" : null}
    >
      <>
        {isBalance ? (
          <i
            className="fas fa-fw fa-balance-scale text-dark"
            title="Балансовый"
          ></i>
        ) : null}
        <i
          className={getIcon(resIcon)}
          style={{ color: resColor ?? null }}
          title={resTitle ?? ""}
        ></i>
        {title} {classTitle ? `(${classTitle})` : ""}
      </>
    </span>
  );
}
