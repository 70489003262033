import BaseForm from "../inServer/BaseForm";

import React from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import ConfirmModal from "../../Components/General/ConfirmModal";

import FormControlsRender from "../../Utils/FormControlsRender";
import { Text } from "../../Utils/FormControlsPresets";
import Guid from "../../Components/Guid";
import { userCan, userCanOr } from "../../Utils/Helpers";

export default class ModuleForm extends BaseForm {
  idField = "id"; //в weather он например CITY_ID

  constructor(props) {
    super(props);

    this.state = {
      requestProcess: false,
      formControls: {
        name: Text("Наименование"),
        action: Text("Модуль"),
      },
      card: {},
      postData: {},
    };

    this.id = props.id; //для обновления ID
  }

  getDateIn() {
    return this.state.postData;
  }

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{`${this.id ? "Редактирование" : "Создание"} модуля`}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={
            userCanOr(this.props.mainUrl, ["update", "create"])
              ? null
              : "disabled"
          }
        >
          <Form>
            <Row>
              <Guid key={this.id} value={this.id} />
            </Row>
            <Row>
              {FormControlsRender(
                "name",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
              )}
            </Row>
            <Row>
              {FormControlsRender(
                "action",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
              )}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {this.id && userCan("module", "delete") ? (
            <Button
              disabled={this.state.requestProcess}
              variant="danger"
              onClick={this.delClick}
            >
              Удалить
            </Button>
          ) : null}
          {userCan(this.props.mainUrl, this.id ? "update" : "create") ? (
            <>
              <Button
                disabled={this.disabledSaveButtons()}
                variant="primary"
                onClick={this.saveClick}
              >
                Сохранить и продолжить
              </Button>
              <Button
                disabled={this.disabledSaveButtons()}
                variant="primary"
                onClick={this.saveCloseClick}
              >
                Сохранить и закрыть
              </Button>
            </>
          ) : null}
          <Button
            disabled={this.state.requestProcess}
            variant="secondary"
            data-dismiss="modal"
            onClick={this.props.hideCallback}
          >
            Отмена
          </Button>
        </Modal.Footer>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    );
  }
}
