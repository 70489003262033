import React from "react";
import { arrayObjToText } from "../../../Utils/Helpers";
import BaseItem from "../BaseItem";

export default class LsItem extends BaseItem {
  render() {
    return (
      <tr
        onClick={this.onClick.bind(this, this.props.info.ID)}
        style={{ cursor: "pointer" }}
      >
        <td>
          <small>{this.props.info.TITLE}</small>
        </td>

        <th>
          <small>{this.props.info.CLIENT_TITLE}</small>
        </th>

        <th>
          <small>{this.props.info.ADDRESS?.TITLE}</small>
        </th>

        <th>{arrayObjToText(this.props.info.COUNTERS, "CODE", 2500)}</th>

        <td>
          <small
            style={{ whiteSpace: "nowrap", overflow: "hidden", width: "200px" }}
          >
            {this.props.info.ORG_TITLE}
          </small>
        </td>

        <td>
          <i
            className="fas fa-fw fa-clipboard"
            title="Скопировать GUID"
            onClick={this.copyCB.bind(this, this.props.info.ID)}
          >
            {" "}
          </i>
          {this.props.info.COMMENT ? (
            <i
              className="fas fa-fw fa-comment-dots"
              title={this.props.info.COMMENT}
            >
              {" "}
            </i>
          ) : (
            <i className="fas fa-fw"> </i>
          )}
        </td>
      </tr>
    );
  }
}
