import BaseForm from "../BaseForm";

import React from "react";
import { Modal, Button, Form, Row } from "react-bootstrap";
import SearchInputObj from "../../../Components/General/SearchInputs/searchInputObj";
import SearchInputAddr from "../../../Components/General/SearchInputs/searchInputAddr";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { default as TabContent } from "../../../Components/General/Tab";
import Map from "../../../Components/Map/Map";
import CounterForm from "../counters/CounterForm";
import Counter from "../../../Components/Devices/Counter";
import ConfirmModal, {
  hideConfirmModal,
} from "../../../Components/General/ConfirmModal";
import { showNotificationErrors } from "../../../Utils/Helpers";

import FormControlsRender from "../../../Utils/FormControlsRender";
import {
  Text,
  TextArea,
  Numeric,
  Checkbox,
  DatePicker,
} from "../../../Utils/FormControlsPresets";

import { RequestWithToken } from "../../../Components/General/Request";
import { toast } from "react-toastify";
import Zone from "../../../Components/Zone";

import {
  permissions,
  canRead,
  canDelete,
} from "../../ServerPermissions/Permissions";

import "./styles.css";

export default class ModemForm extends BaseForm {
  constructor(props) {
    super(props);

    this.isUserZoneAdmin = this.props.serverPermissions?.isZoneAdmin;

    this.state = {
      requestProcess: false,
      card: {},
      formControls: {
        SERNUMBER: Text("Серийный номер"),
        TITLE: Text("Наименование"),
        COMMENT: TextArea("Служебный комментарий"),
        IS_NOT_ACTIVE: Checkbox("Устройство не активно"),
        LATITUDE: Numeric(
          "Коор. (широта)",
          -90,
          90,
          /^\-?\d{0,2}(\.?\d{0,6})?$/,
        ), //eslint-disable-line
        LONGITUDE: Numeric(
          "Коор. (долгота)",
          -180,
          180,
          /^\-?\d{0,3}(\.?\d{0,6})?$/,
        ), //eslint-disable-line
        IMEI: Text("IMEI"),
        ICCID: Text("ICCID"),
        ALLOW_LBS_REQUEST: Checkbox("LBS разрешен"),
        IGNORE_DDOS: Checkbox("Игнорировать проверку «AntiDDoS»"),
        DATE_OF_MANUFACTURE: DatePicker("Дата производства"),
      },
      thisLs: null,
      modalLsTitle: null,
      old_id: null,
      searchLs: false,
      showLsModal: false,
      typeSchet: null,
      postData: {},
      mapModalShow: false,
      counterModalShow: false,
      thisCounter: null,
    };

    this.id = props.id; //для обновления ID
  }

  closeSearchAll = () => {
    this.setState({
      searchClient: false,
      searchOrg: false,
    });
  };

  updClick = (event) => {
    event.preventDefault();

    this.setState({
      confirmModal: {
        text: "Вы действительно хотите обновить список устройств?",
        handleClose: hideConfirmModal.bind(this),
        handleAccept: hideConfirmModal.bind(this, this.upd.bind(this)),
      },
    });
  };

  async upd() {
    RequestWithToken(`retranslate/${this.props.server}/cmd-queue`)
      .post({
        MODEM: this.state.card.ID,
        CMD_CODE: "040A0303",
      })
      .then(() => toast.success("Выполнено"))
      .catch((e) => showNotificationErrors(null, e.response ?? null));
  }

  clickCounterOpen = (id = null) => {
    this.setState({ thisCounter: id, counterModalShow: true });
  };

  hideCounterModal = () => {
    this.setState({ thisCounter: null, counterModalShow: false });
  };

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>
            {(this.state.card.ID ? "Редактирование" : "Создание") +
              " устройства"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="modem-modal">
            <Row>
              {FormControlsRender(
                "SERNUMBER",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-4",
                { disabled: this.isUserZoneAdmin },
              )}
              {FormControlsRender(
                "TITLE",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-5",
              )}
              {FormControlsRender(
                "IS_NOT_ACTIVE",
                this.state.formControls,
                this.onChangeCheckboxHandler,
                this.onSaveHandler(),
                "col-md-3",
                { disabled: this.isUserZoneAdmin },
              )}
            </Row>
            <Row>
              <SearchInputObj
                label="Зона"
                source="zone"
                server={this.props.server}
                render={(item) => <Zone item={item} />}
                onSelectItem={(item) => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, {
                      SERVICE_AREA: item.ID ?? null,
                    }),
                  });
                }}
                defaultItem={{
                  ID: this.state.card.SERVICE_AREA,
                  TITLE: this.state.card.SERVICE_AREA_TITLE,
                }}
              />

              {FormControlsRender(
                "LATITUDE",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-3",
              )}
              {FormControlsRender(
                "LONGITUDE",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-3",
              )}

              <div className="col-md-2">
                <Button
                  variant="secondary"
                  style={{ marginTop: "32px" }}
                  onClick={this.showMapModal()}
                >
                  Карта
                </Button>
              </div>
            </Row>

            <Row>
              <SearchInputAddr
                size="12"
                server={this.props.server}
                closeSearch={this.closeSearchAddr}
                defaultItem={this.state.card?.ADDRESS ?? {}}
                onSelectItem={(item) => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, {
                      ADDRESS: item.ID ?? null,
                    }),
                  });
                }}
                addAddr={this.addAddr}
              />
            </Row>

            <Row>
              {FormControlsRender(
                "COMMENT",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-12",
              )}
            </Row>

            <div style={{ height: "15px" }}></div>

            <Tabs defaultActiveKey="more" id="modem-tabs" className="mb-3">
              {canRead(permissions.counter, this.props.serverPermissions) ? (
                <Tab
                  eventKey="counters"
                  title="Приборы учёта"
                  className="scrolled"
                >
                  <TabContent
                    cards={this.state.card?.COUNTERS ?? []}
                    onChangeHandler={() =>
                      this.loadAndParentReload(this.id, ["COUNTERS"])
                    }
                    renderFunction={(item) => (
                      <Counter
                        item={item}
                        onClick={this.clickCounterOpen.bind(this, item.ID)}
                      />
                    )}
                    renderSearchFunction={(item) => <Counter item={item} />}
                    addUrl={`retranslate/${this.props.server}/modem-counter`}
                    addDataIn={(item) => ({ OWNER: this.id, COUNTER: item.ID })}
                    deleteUrl={
                      this.isUserZoneAdmin &&
                      (this.state.card?.ZONES ?? []).length < 2
                        ? null
                        : (item) =>
                            `retranslate/${this.props.server}/modem-counter/${item.ID_LINK}`
                    }
                    searchUrl={`retranslate/${this.props.server}/counter`}
                    filter={{ noBs: this.id ?? null }}
                    disabled={!this.id}
                  />
                </Tab>
              ) : null}
              <Tab eventKey="more" title="Дополнительно">
                <Row>
                  {FormControlsRender(
                    "IMEI",
                    this.state.formControls,
                    this.onChangeHandler,
                    this.onSaveHandler(),
                    "col-md-4",
                    { disabled: this.isUserZoneAdmin },
                  )}
                  {FormControlsRender(
                    "ICCID",
                    this.state.formControls,
                    this.onChangeHandler,
                    this.onSaveHandler(),
                    "col-md-4",
                    { disabled: this.isUserZoneAdmin },
                  )}

                  <SearchInputObj
                    label="Оператор связи"
                    source="telecom-operator"
                    server={this.props.server}
                    onSelectItem={(item) => {
                      this.setState({
                        postData: Object.assign({}, this.state.postData, {
                          TELECOM_OPERATOR: item.ID ?? null,
                        }),
                      });
                    }}
                    defaultItem={{
                      ID: this.state.card.TELECOM_OPERATOR,
                      TITLE: this.state.card.TELECOM_OPERATOR_TITLE,
                    }}
                    disabled={this.isUserZoneAdmin}
                  />

                  <SearchInputObj
                    label="Резонатор"
                    source="rezon-modem"
                    server={this.props.server}
                    onSelectItem={(item) => {
                      this.setState({
                        postData: Object.assign({}, this.state.postData, {
                          REZON_MODEMS: item.ID ?? null,
                        }),
                      });
                    }}
                    defaultItem={{
                      ID: this.state.card.REZON_MODEMS,
                      TITLE: this.state.card.REZON_MODEMS_TITLE,
                    }}
                    disabled={this.isUserZoneAdmin}
                  />

                  <SearchInputObj
                    label="Модель"
                    source="class-modem"
                    server={this.props.server}
                    onSelectItem={(item) => {
                      this.setState({
                        postData: Object.assign({}, this.state.postData, {
                          CLASS_MODEM: item.ID ?? null,
                        }),
                      });
                    }}
                    defaultItem={{
                      ID: this.state.card.CLASS_MODEM,
                      TITLE: this.state.card.CLASS_MODEM_TITLE,
                    }}
                    disabled={true} //this.isUserZoneAdmin
                  />
                  {FormControlsRender(
                    "DATE_OF_MANUFACTURE",
                    this.state.formControls,
                    this.onChangeDPHandler,
                    this.onSaveHandler(),
                    "col-md-4",
                    { disabled: this.isUserZoneAdmin },
                  )}
                </Row>
                <Row>
                  <SearchInputObj
                    label="Комплектация"
                    source="complect-modem"
                    server={this.props.server}
                    onSelectItem={(item) => {
                      this.setState({
                        postData: Object.assign({}, this.state.postData, {
                          COMPLECT_MODEMS: item.ID ?? null,
                        }),
                      });
                    }}
                    defaultItem={{
                      ID: this.state.card.COMPLECT_MODEMS,
                      TITLE: this.state.card.COMPLECT_MODEMS_TITLE,
                    }}
                    disabled={this.isUserZoneAdmin}
                  />
                  {FormControlsRender(
                    "ALLOW_LBS_REQUEST",
                    this.state.formControls,
                    this.onChangeCheckboxHandler,
                    this.onSaveHandler(),
                    "col-md-4",
                    { disabled: this.isUserZoneAdmin },
                  )}
                  {FormControlsRender(
                    "IGNORE_DDOS",
                    this.state.formControls,
                    this.onChangeCheckboxHandler,
                    this.onSaveHandler(),
                    "col-md-4",
                    { disabled: this.isUserZoneAdmin },
                  )}
                </Row>
              </Tab>
            </Tabs>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {this.state.card.TEMPLATE === "uspd02" ? (
            <Button variant="info" onClick={this.updClick}>
              Обновить список устройств
            </Button>
          ) : null}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {this.id &&
          canDelete(permissions.sensor, this.props.serverPermissions) ? (
            <Button
              disabled={this.state.requestProcess}
              variant="danger"
              onClick={this.delClick}
            >
              Удалить
            </Button>
          ) : null}
          <Button
            disabled={this.disabledSaveButtons()}
            variant="primary"
            onClick={this.saveClick}
          >
            Сохранить и продолжить
          </Button>
          <Button
            disabled={this.disabledSaveButtons()}
            variant="primary"
            onClick={this.saveCloseClick}
          >
            Сохранить и закрыть
          </Button>
          <Button
            disabled={this.state.requestProcess}
            variant="secondary"
            onClick={this.cancelClick}
          >
            Отмена
          </Button>
        </Modal.Footer>

        <Modal
          show={this.state.mapModalShow}
          onHide={this.hideMapModal()}
          size="xl"
        >
          <Map
            coordinates={[
              this.state.formControls.LATITUDE.value,
              this.state.formControls.LONGITUDE.value,
            ]}
            hideCallback={(coordinates) => {
              this.coordinatesToState(coordinates);
              this.hideMapModal()();
            }}
          />
        </Modal>

        <Modal
          show={this.state.counterModalShow}
          onHide={this.hideCounterModal}
          size="xl"
        >
          <CounterForm
            server={this.props.server}
            id={this.state.thisCounter}
            hideCallback={this.hideCounterModal}
            parentReloadCallback={() =>
              this.loadAndParentReload(this.id, ["COUNTERS"])
            }
            mainUrl="counter"
          />
        </Modal>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    );
  }
}
