import BaseForm from "../BaseForm";

import React from "react";
import { Modal, Button, Form, Nav, Row } from "react-bootstrap";
import SearchInputAddr from "../../../Components/General/SearchInputs/searchInputAddr";
import SearchInputObj from "../../../Components/General/SearchInputs/searchInputObj";
import Tab from "../../../Components/General/Tab";
import CounterForm from "../counters/CounterForm";
import Counter from "../../../Components/Devices/Counter";
import ValveNbiotForm from "../valves_nbiot/ValveNbiotForm";
import ValveNbiot from "../../../Components/Devices/ValveNbiot";
import LeakageForm from "../leakages/LeakageForm";
import LeakageSensor from "../../../Components/Devices/LeakageSensor";

import FormControlsRender from "../../../Utils/FormControlsRender";
import { Text, TextArea, Checkbox } from "../../../Utils/FormControlsPresets";
import ConfirmModal from "../../../Components/General/ConfirmModal";
import Sensor from "../../../Components/Devices/Sensor";
import SensorForm from "../sensors/SensorForm";

export default class LsForm extends BaseForm {
  constructor(props) {
    super(props);

    this.state = {
      navKey: "1",
      requestProcess: false,
      searchAddr: false,
      card: {},
      formControls: {
        NUMBER: Text("Номер"),
        TITLE: Text("Наименование"),
        LIC_SCHET_VNESH: Text(
          "Номер лицевого счета во внешней информационной системе",
        ),
        ABONENTPLUS: Text('Номер л/с "Абонент+"'),
        COMMENT: TextArea("Служебный комментарий"),
        IS_YUR: Checkbox("Юр. лицо"),
      },
      postData: {},
      counterModalShow: false,
      valveNbiotModalShow: false,
      leakageModalShow: false,
      sensorModalShow: false,
      thisTabId: null,
    };

    this.id = props.id; //для обновления ID
  }

  closeSearchAll = () => {
    this.setState({
      searchClient: false,
      searchOrg: false,
    });
  };

  clickModalOpen = (modalVariableShow, id = null) =>
    this.setState({ thisTabId: id, [modalVariableShow]: true });

  hideModals = () => {
    this.setState({
      thisTabId: null,
      counterModalShow: false,
      valveNbiotModalShow: false,
      leakageModalShow: false,
      sensorModalShow: false,
    });
  };

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>
            {(this.state.card.ID ? "Редактирование" : "Создание") +
              " лицевого счета"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              {FormControlsRender(
                "NUMBER",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-3",
              )}
              {FormControlsRender(
                "TITLE",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-3",
              )}

              <SearchInputObj
                label="Организация"
                source="org"
                size={6}
                server={this.props.server}
                onSelectItem={(item) => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, {
                      ORG: item.ID ?? null,
                    }),
                  });
                }}
                defaultItem={{
                  ID: this.state.card.ORG,
                  TITLE: this.state.card.ORG_TITLE,
                }}
              />
            </Row>
            <Row>
              <SearchInputAddr
                key={this.state.card?.ADDRESS?.ID ?? "address"}
                size="12"
                server={this.props.server}
                closeSearch={this.closeSearchAddr}
                defaultItem={this.state.card?.ADDRESS ?? {}}
                onSelectItem={(item) => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, {
                      ADDRESS: item.ID ?? null,
                    }),
                  });
                }}
                addAddr={this.addAddr}
              />
            </Row>
            <Row>
              <SearchInputObj
                label="Плательщик"
                source="client"
                size={12}
                server={this.props.server}
                onSelectItem={(item) => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, {
                      CLIENTS: item.ID ?? null,
                    }),
                  });
                }}
                defaultItem={{
                  ID: this.state.card.CLIENTS,
                  TITLE: this.state.card.CLIENTS_TITLE,
                }}
              />
            </Row>
            <Row>
              {FormControlsRender(
                "LIC_SCHET_VNESH",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-6",
              )}
              {FormControlsRender(
                "ABONENTPLUS",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-6",
              )}
            </Row>
            <Row>
              {FormControlsRender(
                "COMMENT",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-9",
              )}
              {FormControlsRender(
                "IS_YUR",
                this.state.formControls,
                this.onChangeCheckboxHandler,
                this.onSaveHandler(),
                "col-md-3",
              )}
            </Row>

            <Nav
              variant="tabs"
              defaultActiveKey={this.state.navKey}
              onSelect={(key) => this.setState({ navKey: key })}
            >
              <Nav.Item>
                <Nav.Link eventKey="1">Приборы учёта</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="2">Датчики протечки</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="3">Запорная арматура Nbiot</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="4">Термогигрометры</Nav.Link>
              </Nav.Item>
            </Nav>

            <div className="tab-content tabContent" id="nav-tabContent">
              <span
                style={{ display: this.state.navKey !== "1" ? "none" : null }}
              >
                <Tab
                  cards={this.state.card?.COUNTERS ?? []}
                  onChangeHandler={() =>
                    this.loadAndParentReload(this.id, ["COUNTERS"])
                  }
                  renderFunction={(item) => (
                    <Counter
                      item={item}
                      onClick={this.clickModalOpen.bind(
                        this,
                        "counterModalShow",
                        item.ID,
                      )}
                    />
                  )}
                  renderSearchFunction={(item) => <Counter item={item} />}
                  addUrl={`retranslate/${this.props.server}/lic-counter`}
                  addDataIn={(item) => ({ OWNER: this.id, COUNTER: item.ID })}
                  deleteUrl={(item) =>
                    `retranslate/${this.props.server}/lic-counter/${item.ID_LINK}`
                  }
                  searchUrl={`retranslate/${this.props.server}/counter`}
                  filter={{ noLs: this.id ?? null }}
                  disabled={!this.id}
                />
              </span>
              <span
                style={{ display: this.state.navKey !== "2" ? "none" : null }}
              >
                <Tab
                  cards={this.state.card?.LEAKAGES ?? []}
                  onChangeHandler={() =>
                    this.loadAndParentReload(this.id, ["LEAKAGES"])
                  }
                  renderFunction={(item) => (
                    <LeakageSensor
                      item={item}
                      onClick={this.clickModalOpen.bind(
                        this,
                        "leakageModalShow",
                        item.ID,
                      )}
                    />
                  )}
                  renderSearchFunction={(item) => <LeakageSensor item={item} />}
                  addUrl={`retranslate/${this.props.server}/lic-leakage-sensor`}
                  addDataIn={(item) => ({
                    LIC_SCHET: this.id,
                    SENSOR: item.ID,
                  })}
                  deleteUrl={(item) =>
                    `retranslate/${this.props.server}/lic-leakage-sensor/${item.ID_LINK}`
                  }
                  searchUrl={`retranslate/${this.props.server}/leakage-sensor`}
                  filter={{ noLs: this.id ?? null }}
                  disabled={!this.id}
                />
              </span>
              <span
                style={{ display: this.state.navKey !== "3" ? "none" : null }}
              >
                <Tab
                  cards={this.state.card?.VALVES_NBIOT ?? []}
                  onChangeHandler={() =>
                    this.loadAndParentReload(this.id, ["VALVES_NBIOT"])
                  }
                  renderFunction={(item) => (
                    <ValveNbiot
                      item={item}
                      onClick={this.clickModalOpen.bind(
                        this,
                        "valveNbiotModalShow",
                        item.ID,
                      )}
                    />
                  )}
                  renderSearchFunction={(item) => <ValveNbiot item={item} />}
                  addUrl={`retranslate/${this.props.server}/lic-valve-nbiot`}
                  addDataIn={(item) => ({ LIC_SCHET: this.id, VALVE: item.ID })}
                  deleteUrl={(item) =>
                    `retranslate/${this.props.server}/lic-valve-nbiot/${item.ID_LINK}`
                  }
                  searchUrl={`retranslate/${this.props.server}/valve-nbiot`}
                  filter={{ noLs: this.id ?? null }}
                  disabled={!this.id}
                />
              </span>
              <span
                style={{ display: this.state.navKey !== "4" ? "none" : null }}
              >
                <Tab
                  cards={this.state.card?.SENSORS ?? []}
                  onChangeHandler={() =>
                    this.loadAndParentReload(this.id, ["SENSORS"])
                  }
                  renderFunction={(item) => (
                    <Sensor
                      item={item}
                      onClick={this.clickModalOpen.bind(
                        this,
                        "sensorModalShow",
                        item.ID,
                      )}
                    />
                  )}
                  renderSearchFunction={(item) => <Sensor item={item} />}
                  addUrl={`retranslate/${this.props.server}/lic-sensor`}
                  addDataIn={(item) => ({
                    LIC_SCHET: this.id,
                    SENSOR: item.ID,
                  })}
                  deleteUrl={(item) =>
                    `retranslate/${this.props.server}/lic-sensor/${item.ID_LINK}`
                  }
                  searchUrl={`retranslate/${this.props.server}/sensor`}
                  filter={{ noLs: this.id ?? null }}
                  disabled={!this.id}
                />
              </span>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {this.id ? (
            <Button
              disabled={this.state.requestProcess}
              variant="danger"
              onClick={this.delClick}
            >
              Удалить
            </Button>
          ) : null}
          <Button
            disabled={this.disabledSaveButtons()}
            variant="primary"
            onClick={this.saveClick}
          >
            Сохранить и продолжить
          </Button>
          <Button
            disabled={this.disabledSaveButtons()}
            variant="primary"
            onClick={this.saveCloseClick}
          >
            Сохранить и закрыть
          </Button>
          <Button
            disabled={this.state.requestProcess}
            variant="secondary"
            onClick={this.cancelClick}
          >
            Отмена
          </Button>
        </Modal.Footer>

        <Modal
          show={this.state.counterModalShow}
          onHide={this.hideModals}
          size="xl"
        >
          <CounterForm
            server={this.props.server}
            id={this.state.thisTabId}
            hideCallback={this.hideModals}
            parentReloadCallback={() =>
              this.loadAndParentReload(this.id, ["COUNTERS"])
            }
            mainUrl="counter"
          />
        </Modal>

        <Modal
          show={this.state.valveNbiotModalShow}
          onHide={this.hideModals}
          size="xl"
        >
          <ValveNbiotForm
            server={this.props.server}
            id={this.state.thisTabId}
            hideCallback={this.hideModals}
            parentReloadCallback={() =>
              this.loadAndParentReload(this.id, ["VALVES_NBIOT"])
            }
            mainUrl="valve-nbiot"
          />
        </Modal>

        <Modal
          show={this.state.sensorModalShow}
          onHide={this.hideModals}
          size="xl"
        >
          <SensorForm
            server={this.props.server}
            id={this.state.thisTabId}
            hideCallback={this.hideModals}
            parentReloadCallback={() =>
              this.loadAndParentReload(this.id, ["SENSORS"])
            }
            mainUrl="sensor"
          />
        </Modal>

        <Modal
          show={this.state.leakageModalShow}
          onHide={this.hideModals}
          size="xl"
        >
          <LeakageForm
            server={this.props.server}
            id={this.state.thisTabId}
            hideCallback={this.hideModals}
            parentReloadCallback={() =>
              this.loadAndParentReload(this.id, ["LEAKAGES"])
            }
            mainUrl="leakage-sensor"
          />
        </Modal>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    );
  }
}
