import React, { useState, useEffect, useRef } from "react";
import { getObjHashCode } from "../../../Utils/Helpers";

import styles from "./TableSearch.module.css";

export default function TableSearch(props) {
  useEffect(() => {
    props.setParentCallback("reloadCallback", load);
    props.setParentCallback("setFilter", setFilter);
    // eslint-disable-next-line
  }, [getObjHashCode(props.filter ?? [])]);

  const [searchText, setSearchText] = useState("");
  const [searchMode, setSearchMode] = useState(0);
  const [filterHidden, setFilterHidden] = useState(true);
  const [filterTime, setFilterTime] = useState(new Date());
  const [curFilter, setCurFilter] = useState({});

  const load = (replace = true) =>
    props.searchParams.handleLoad(searchText, searchMode, replace, curFilter);

  const searchInput = (e) => setSearchText(e.target.value);

  const isNotDebouncingLoad = useRef(true);

  useEffect(() => {
    if (isNotDebouncingLoad.current) {
      isNotDebouncingLoad.current = false;
      return;
    }

    const timer = setTimeout(() => {
      load();
    }, 500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [searchText, searchMode, getObjHashCode(curFilter)]);

  const searchKeyUp = (e) => {
    if (e.keyCode === 13) {
      load();
    }
  };

  const changeSearchMode = () => setSearchMode(searchMode === 0 ? 1 : 0);

  const setFilter = (key, value) => {
    let filter = curFilter;

    if (value === null || value === undefined) {
      delete curFilter[key];
    } else {
      filter[key] = value;
    }

    setCurFilter({ ...filter });
  };

  const clearSearch = () => {
    setSearchText("");
    setCurFilter({});
    setFilterTime(new Date());
    setFilterHidden(true);
    window.scrollTo(0, 0);
  };

  const filterClick = () => setFilterHidden(!filterHidden);

  const title = `Поиск по ${props.text ?? "данным"}`;
  const filterLength = Object.values(curFilter).length;
  const filtered = filterLength > 0 ? filterLength : "";

  return (
    <div className="input-group mb-3 searchInputInSrv">
      <input
        type="text"
        title={title}
        placeholder={title}
        className="form-control"
        value={searchText}
        onChange={searchInput}
        onKeyUp={searchKeyUp}
      />
      <div className="input-group-append">
        <button
          className="btn btn-default"
          type="button"
          onClick={changeSearchMode}
          title={searchMode ? "Строгий поиск" : "Нестрогий поиск"}
        >
          {searchMode ? (
            <i className="fas fa-fw fa-equals"> </i>
          ) : (
            <i className="fas fa-fw fa-not-equal"> </i>
          )}
        </button>
        <button
          className="btn btn-default"
          type="button"
          onClick={clearSearch}
          title="Очистить поиск"
        >
          <i className="fas fa-fw fa-times"></i>
        </button>
        {props.filter ? (
          <>
            <button
              className="btn btn-default"
              type="button"
              onClick={filterClick}
              title="Фильтр"
            >
              <i className="fas fa-fw fa-filter">
                <sup>{filtered}</sup>
              </i>
            </button>
          </>
        ) : null}
        <div
          key={filterTime}
          hidden={filterHidden}
          className={styles.filtersWrapper}
        >
          {props.filter}
        </div>
      </div>
    </div>
  );
}
