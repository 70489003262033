import BaseForm from "../BaseForm";

import React from "react";
import { Button, Form, Modal, Image, Row } from "react-bootstrap";

import { toast } from "react-toastify";

import { RequestWithToken } from "../../../Components/General/Request";
import FormControlsRender from "../../../Utils/FormControlsRender";
import {
  Text,
  TextArea,
  Numeric,
  DatePicker,
  Checkbox,
} from "../../../Utils/FormControlsPresets";
import SearchInputObj from "../../../Components/General/SearchInputs/searchInputObj";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { default as TabContent } from "../../../Components/General/Tab";
import Guid from "../../../Components/Guid";
import { getMimeByExt } from "../../../Utils/Helpers";
import InputFileButton from "../../../Components/General/Inputs/Abonent/inpFileButton";
import DevicePlanForm from "./DevicePlanForm";
import { showNotificationErrors } from "../../../Utils/Helpers";
import ConfirmModal from "../../../Components/General/ConfirmModal";
import Counter from "../../../Components/Devices/Counter";
import LeakageSensor from "../../../Components/Devices/LeakageSensor";
import Sensor from "../../../Components/Devices/Sensor";
import ValveNbiot from "../../../Components/Devices/ValveNbiot";

import Zone from "../../../Components/Zone";

import {
  permissions,
  canRead,
  canDelete,
} from "../../ServerPermissions/Permissions";
import getDeviceIconStyle from "./icons";

export default class PlanForm extends BaseForm {
  constructor(props) {
    super(props);

    this.isUserZoneAdmin = this.props.serverPermissions?.isZoneAdmin;

    this.state = {
      requestProcess: false,
      card: {},
      formControls: {
        TITLE: Text("Наименование"),
        COMMENT: TextArea("Служебный комментарий"),
        PRIM: Text("Примечание"),
        SORT: Numeric("Порядок сортировки"),
        LENGTH: Numeric("Длина, м", 0, 999.99, /^\d{0,3}(\.?\d{0,2})?$/),
        WIDTH: Numeric("Ширина, м", 0, 999.99, /^\d{0,3}(\.?\d{0,2})?$/),
        HEIGHT: Numeric("Высота, м", 0, 999.99, /^\d{0,3}(\.?\d{0,2})?$/),
        DATEON: DatePicker("Дата создания уч.записи"),
        ZONE_ID: {},
        IS_SHOW_SPO: Checkbox("Отображать план в СПО"),
        IS_SHOW_STAND: Checkbox("Отображать план на стенде"),
      },
      postData: {},
      devicePlanModal: {},
      editPlanModalShow: false,
      image: null,
      showModalImage: false,
    };

    this.coord = null;
    this.id = props.id; //для обновления ID
  }

  openImageClick = () => {
    if (this.state.image) {
      this.setState({ showModalImage: true });
    } else {
      this.loadFile(() => this.setState({ showModalImage: true }));
    }
  };

  saveImageClick = () => {
    if (this.state.image) {
      this.setState(this.saveImage);
    } else {
      this.loadFile(this.saveImage);
    }
  };

  editPlanClick = () => {
    if (this.state.image) {
      this.setState({ editPlanModalShow: true });
    } else {
      this.loadFile(() => this.setState({ editPlanModalShow: true }));
    }
  };

  saveImage = () => {
    const tempLink = document.createElement("a");
    tempLink.href = this.state.image.url;
    tempLink.setAttribute("download", `${this.state.card?.IMAGE}`);
    tempLink.click();
  };

  loadFile = (callback) => {
    this.setState({ requestProcess: true });

    RequestWithToken(
      `retranslate/${this.props.server}/${this.props.mainUrl}/file/${this.id}`,
    )
      .getFile()
      .then((data) => {
        const type = data.type ?? getMimeByExt(this.state.card.IMAGE);
        const downloadedFile = new Blob([data], { type }); //Build a URL from the file
        const url = URL.createObjectURL(downloadedFile); //Open the URL on new Window

        this.setState({ image: { url, type } }, callback);
      })
      .catch((e) => {
        showNotificationErrors("Ошибка загрузки файла", e.response ?? null);
      })
      .finally(() => this.setState({ requestProcess: false }));
  };

  uploadFile = (event) => {
    const file = event.target.files[0];

    if (file) {
      this.setState({ requestProcess: true });

      const path_splitted = file.name.split(".");
      const extension = path_splitted.pop();
      let datein = new FormData();
      datein.append("files[]", file, `${event.target.name}.${extension}`);

      RequestWithToken(
        `retranslate/${this.props.server}/${this.props.mainUrl}/file`,
      )
        .setHeaders({ "Content-Type": "multipart/form-data" })
        .post(datein)
        .then((data) => {
          let postData = this.state.postData;
          postData.IMAGE = data.items?.name;
          this.setState({ postData, image: null });
          toast.success("Файл успешно загружен");
        })
        .catch((e) => {
          showNotificationErrors("Ошибка загрузки файла", e.response ?? null);
        })
        .finally(() => {
          event.target.value = null;
          this.setState({ requestProcess: false, image: null });
        });
    }
  };

  allowDrop = (e) => {
    e.preventDefault();
  };

  onDragEnd = (id, url, e) => {
    const parent = e.target.parentElement.getBoundingClientRect();
    const diffY = this.coord.top - e.screenY;
    const top = e.target.offsetTop - diffY + e.target.offsetHeight / 2;
    const topPers = Math.round((top / parent.height) * 100);

    const diffX = this.coord.left - e.screenX;
    const left = e.target.offsetLeft - diffX + e.target.offsetWidth / 2;
    const leftPers = Math.round((left / parent.width) * 100);

    if (topPers < 0 || topPers > 100 || leftPers < 0 || leftPers > 100) {
      e.preventDefault();
      return false;
    }

    e.target.style.top = topPers + "%";
    e.target.style.left = leftPers + "%";

    this.saveCoord(id, url, topPers, leftPers);
  };

  async saveCoord(id, url, top, left) {
    RequestWithToken(`retranslate/${this.props.server}/${url}/${id}`)
      .put({
        TOP: top,
        LEFT: left,
      })
      .then(() => toast.success("Успешно"))
      .catch(() => toast.error("Ошибка"))
      .finally(() => this.setState({ requestProcess: false }));
  }

  clickSensorPlanOpen = (id = null) => {
    if (this.state.image) {
      this.setState({
        devicePlanModal: {
          id,
          mainUrl: "sensor-plan",
          devUrl: "sensor",
          devField: "SENSOR_ID",
        },
      });
    } else {
      this.loadFile(this.clickSensorPlanOpen.bind(this, id));
    }
  };

  clickSensorPlanAdd = (device = null) => {
    if (this.state.image) {
      this.setState({
        devicePlanModal: {
          id: null,
          mainUrl: "sensor-plan",
          devUrl: "sensor",
          devField: "SENSOR_ID",
          device,
        },
      });
    } else {
      this.loadFile(this.clickSensorPlanAdd.bind(this, device));
    }
  };

  clickLeakageSensorPlanOpen = (id = null) => {
    if (this.state.image) {
      this.setState({
        devicePlanModal: {
          id,
          mainUrl: "leakage-sensor-plan",
          devUrl: "leakage-sensor",
          devField: "SENSOR_ID",
        },
      });
    } else {
      this.loadFile(this.clickLeakageSensorPlanOpen.bind(this, id));
    }
  };

  clickLeakageSensorPlanAdd = (device = null) => {
    if (this.state.image) {
      this.setState({
        devicePlanModal: {
          id: null,
          mainUrl: "leakage-sensor-plan",
          devUrl: "leakage-sensor",
          devField: "SENSOR_ID",
          device,
        },
      });
    } else {
      this.loadFile(this.clickLeakageSensorPlanAdd.bind(this, device));
    }
  };

  clickValveNbiotPlanOpen = (id = null) => {
    if (this.state.image) {
      this.setState({
        devicePlanModal: {
          id,
          mainUrl: "valve-nbiot-plan",
          devUrl: "valve-nbiot",
          devField: "VALVE_ID",
        },
      });
    } else {
      this.loadFile(this.clickValveNbiotPlanOpen.bind(this, id));
    }
  };

  clickValveNbiotPlanAdd = (device = null) => {
    if (this.state.image) {
      this.setState({
        devicePlanModal: {
          id: null,
          mainUrl: "valve-nbiot-plan",
          devUrl: "valve-nbiot",
          devField: "VALVE_ID",
          device,
        },
      });
    } else {
      this.loadFile(this.clickValveNbiotPlanAdd.bind(this, device));
    }
  };

  clickCounterPlanOpen = (id = null) => {
    if (this.state.image) {
      this.setState({
        devicePlanModal: {
          id,
          mainUrl: "counter-plan",
          devUrl: "counter",
          devField: "COUNTER_ID",
        },
      });
    } else {
      this.loadFile(this.clickCounterPlanOpen.bind(this, id));
    }
  };

  clickCounterPlanAdd = (device = null) => {
    if (this.state.image) {
      this.setState({
        devicePlanModal: {
          id: null,
          mainUrl: "counter-plan",
          devUrl: "counter",
          devField: "COUNTER_ID",
          device,
        },
      });
    } else {
      this.loadFile(this.clickCounterPlanAdd.bind(this, device));
    }
  };

  hidePlanModals = () => {
    this.setState({ devicePlanModal: {}, editPlanModalShow: false });
  };

  // getDeviceClass = icon => {
  //   switch (icon) {
  //     case 'sensor':
  //       return styles.sensorOnPlan
  //     case 'leakage-sensor':
  //       return styles.leakageSensorOnPlan
  //     case 'valve-nbiot':
  //       return styles.valveNbiotOnPlan
  //     case 'electro':
  //       return styles.counterElectroOnPlan
  //     case 'w-cold':
  //       return styles.counterColdOnPlan
  //     case 'w-hot':
  //       return styles.counterHotOnPlan
  //     case 'gaz':
  //       return styles.counterGazOnPlan
  //     case 'colorimetr':
  //       return styles.counterColorOnPlan
  //     default:
  //       return styles.counterElectroOnPlan
  //   }
  // }

  RenderDevicesOnPlan = (props) => {
    return (
      <>
        {(props.items ?? []).map((item) => {
          return (
            <div
              key={item.ID}
              className={getDeviceIconStyle(item.ICON)}
              style={{ top: `${item.TOP}%`, left: `${item.LEFT}%` }}
              draggable="true"
              title={item.SERIAL_NUMBER ?? item.CODE}
              onDragStart={(e) => {
                this.coord = { top: e.screenY, left: e.screenX };
              }}
              onDragEnd={this.onDragEnd.bind(this, item.ID_LINK, props.url)}
            />
          );
        })}
      </>
    );
  };

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{`${this.id ? "Редактирование" : "Создание"} плана`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Guid key={this.id} value={this.id} />
            </Row>
            <Row
              className={
                this.state.card?.IMAGE || this.state.postData?.IMAGE
                  ? null
                  : "disabled"
              }
            >
              {FormControlsRender(
                "TITLE",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-4",
              )}
              {FormControlsRender(
                "SORT",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-4",
              )}
              {FormControlsRender(
                "DATEON",
                this.state.formControls,
                this.onChangeDPHandler,
                this.onSaveHandler(),
                "col-md-4",
                { disabled: true },
              )}
            </Row>
            <Row
              className={
                this.state.card?.IMAGE || this.state.postData?.IMAGE
                  ? null
                  : "disabled"
              }
            >
              <SearchInputObj
                size={4}
                label="Зона"
                source="zone"
                server={this.props.server}
                render={(item) => <Zone item={item} />}
                onSelectItem={(item) => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, {
                      ZONE_ID: item.ID ?? null,
                    }),
                  });
                }}
                defaultItem={this.state.card.ZONE}
                errorMessage={this.state.formControls.ZONE_ID?.errorMessage}
              />
              {FormControlsRender(
                "PRIM",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-8",
              )}
            </Row>

            <Row>
              <div className="tarif_group">
                <div className="tarif_group_title">Размеры помещения</div>
                <Row>
                  {FormControlsRender(
                    "HEIGHT",
                    this.state.formControls,
                    this.onChangeHandler,
                    this.onSaveHandler(),
                    "col-md-4",
                  )}
                  {FormControlsRender(
                    "WIDTH",
                    this.state.formControls,
                    this.onChangeHandler,
                    this.onSaveHandler(),
                    "col-md-4",
                  )}
                  {FormControlsRender(
                    "LENGTH",
                    this.state.formControls,
                    this.onChangeHandler,
                    this.onSaveHandler(),
                    "col-md-4",
                  )}
                </Row>
              </div>
            </Row>
            <Row>
              {FormControlsRender(
                "COMMENT",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-12",
              )}
            </Row>

            <Row>
              {FormControlsRender(
                "IS_SHOW_SPO",
                this.state.formControls,
                this.onChangeCheckboxHandler,
                this.onSaveHandler(),
                "col-md-4",
              )}
              {FormControlsRender(
                "IS_SHOW_STAND",
                this.state.formControls,
                this.onChangeCheckboxHandler,
                this.onSaveHandler(),
                "col-md-4",
                { disabled: this.isUserZoneAdmin },
              )}
            </Row>

            <Row>
              <div className="col-md-12">
                <label className="form-label">Изображение</label>
                <div className="pers_sogl">
                  {this.state.card?.IMAGE || this.state.postData?.IMAGE ? (
                    <>
                      <Button
                        disabled={this.state.postData?.IMAGE ? true : false}
                        onClick={this.openImageClick}
                        variant="secondary"
                        title="Открыть"
                      >
                        Открыть
                      </Button>
                      <Button
                        disabled={this.state.postData?.IMAGE ? true : false}
                        onClick={this.saveImageClick}
                        variant="secondary"
                        title="Скачать"
                      >
                        Скачать
                      </Button>
                      <InputFileButton
                        onChange={this.uploadFile}
                        label="Заменить"
                      />
                      <Button
                        disabled={this.state.postData?.IMAGE ? true : false}
                        onClick={this.editPlanClick}
                        variant="secondary"
                        className={"float-right"}
                        title="Размещение"
                      >
                        Размещение
                      </Button>
                    </>
                  ) : (
                    <InputFileButton
                      onChange={this.uploadFile}
                      label="Выбрать файл"
                    />
                  )}
                </div>
              </div>
            </Row>

            <div style={{ height: "15px" }}></div>

            <Tabs id="plan-tabs" className="mb-3">
              {canRead(permissions.sensor, this.props.serverPermissions) ? (
                <Tab eventKey="sensors" title="Датчики">
                  <TabContent
                    cards={this.state.card?.SENSORS ?? []}
                    onChangeHandler={() =>
                      this.loadAndParentReload(this.id, ["SENSORS"])
                    }
                    renderFunction={(item) => (
                      <Sensor
                        item={item}
                        onClick={this.clickSensorPlanOpen.bind(
                          this,
                          item.ID_LINK ?? item.ID,
                        )}
                      />
                    )}
                    renderSearchFunction={(item) => <Sensor item={item} />}
                    addUrl={`retranslate/${this.props.server}/sensor-plan`}
                    addDataIn={(item) => ({
                      PLAN_ID: this.id,
                      SENSOR_ID: item.ID,
                    })}
                    deleteUrl={(item) =>
                      `retranslate/${this.props.server}/sensor-plan/${item.ID_LINK}`
                    }
                    searchUrl={`retranslate/${this.props.server}/sensor`}
                    onSearchSelect={this.clickSensorPlanAdd}
                    filter={{ noPlan: this.id ?? null }}
                    image={this.state.image}
                    disabled={!this.id}
                  />
                </Tab>
              ) : null}
              {canRead(
                permissions.leakageSensor,
                this.props.serverPermissions,
              ) ? (
                <Tab eventKey="leakageSensor" title="Датчики протечки">
                  <TabContent
                    cards={this.state.card?.LEAKAGE_SENSORS ?? []}
                    onChangeHandler={() =>
                      this.loadAndParentReload(this.id, ["LEAKAGE_SENSORS"])
                    }
                    renderFunction={(item) => (
                      <LeakageSensor
                        item={item}
                        onClick={this.clickLeakageSensorPlanOpen.bind(
                          this,
                          item.ID_LINK ?? item.ID,
                        )}
                      />
                    )}
                    renderSearchFunction={(item) => (
                      <LeakageSensor item={item} />
                    )}
                    addUrl={`retranslate/${this.props.server}/leakage-sensor-plan`}
                    addDataIn={(item) => ({
                      PLAN_ID: this.id,
                      SENSOR_ID: item.ID,
                    })}
                    deleteUrl={(item) =>
                      `retranslate/${this.props.server}/leakage-sensor-plan/${item.ID_LINK}`
                    }
                    searchUrl={`retranslate/${this.props.server}/leakage-sensor`}
                    onSearchSelect={this.clickLeakageSensorPlanAdd}
                    filter={{ noPlan: this.id ?? null }}
                    image={this.state.image}
                    disabled={!this.id}
                  />
                </Tab>
              ) : null}
              {canRead(permissions.valveNbiot, this.props.serverPermissions) ? (
                <Tab eventKey="valveNbiot" title="Запорная арматура Nbiot">
                  <TabContent
                    cards={this.state.card?.VALVES_NBIOT ?? []}
                    onChangeHandler={() =>
                      this.loadAndParentReload(this.id, ["VALVES_NBIOT"])
                    }
                    renderFunction={(item) => (
                      <ValveNbiot
                        item={item}
                        onClick={this.clickValveNbiotPlanOpen.bind(
                          this,
                          item.ID_LINK ?? item.ID,
                        )}
                      />
                    )}
                    renderSearchFunction={(item) => <ValveNbiot item={item} />}
                    addUrl={`retranslate/${this.props.server}/valve-nbiot-plan`}
                    addDataIn={(item) => ({
                      PLAN_ID: this.id,
                      VALVE_ID: item.ID,
                    })}
                    deleteUrl={(item) =>
                      `retranslate/${this.props.server}/valve-nbiot-plan/${item.ID_LINK}`
                    }
                    searchUrl={`retranslate/${this.props.server}/valve-nbiot`}
                    onSearchSelect={this.clickValveNbiotPlanAdd}
                    filter={{ noPlan: this.id ?? null }}
                    image={this.state.image}
                    disabled={!this.id}
                  />
                </Tab>
              ) : null}
              {canRead(permissions.counter, this.props.serverPermissions) ? (
                <Tab eventKey="counter" title="Приборы учета">
                  <TabContent
                    cards={this.state.card?.COUNTERS ?? []}
                    onChangeHandler={() =>
                      this.loadAndParentReload(this.id, ["COUNTERS"])
                    }
                    renderFunction={(item) => (
                      <Counter
                        item={item}
                        onClick={this.clickCounterPlanOpen.bind(
                          this,
                          item.ID_LINK ?? item.ID,
                        )}
                      />
                    )}
                    renderSearchFunction={(item) => <Counter item={item} />}
                    addUrl={`retranslate/${this.props.server}/counter-plan`}
                    addDataIn={(item) => ({
                      PLAN_ID: this.id,
                      COUNTER_ID: item.ID,
                    })}
                    deleteUrl={(item) =>
                      `retranslate/${this.props.server}/counter-plan/${item.ID_LINK}`
                    }
                    searchUrl={`retranslate/${this.props.server}/counter`}
                    onSearchSelect={this.clickCounterPlanAdd}
                    filter={{ noPlan: this.id ?? null }}
                    image={this.state.image}
                    disabled={!this.id}
                  />
                </Tab>
              ) : null}
            </Tabs>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {this.id &&
          canDelete(permissions.plan, this.props.serverPermissions) ? (
            <Button
              disabled={this.state.requestProcess}
              variant="danger"
              onClick={this.delClick}
            >
              Удалить
            </Button>
          ) : null}
          <Button
            disabled={this.disabledSaveButtons()}
            variant="primary"
            onClick={this.saveClick}
          >
            Сохранить и продолжить
          </Button>
          <Button
            disabled={this.disabledSaveButtons()}
            variant="primary"
            onClick={this.saveCloseClick}
          >
            Сохранить и закрыть
          </Button>
          <Button
            disabled={this.state.requestProcess}
            variant="secondary"
            onClick={this.cancelClick}
          >
            Отмена
          </Button>
        </Modal.Footer>

        <Modal
          show={Object.keys(this.state.devicePlanModal).length}
          onHide={this.hidePlanModals}
          size="xl"
          dialogClassName="modal-90w1"
        >
          <DevicePlanForm
            server={this.props.server}
            id={this.state.devicePlanModal.id}
            card={{
              PLAN: { ID: this.id, TITLE: this.state.card?.TITLE },
              DEVICE: this.state.devicePlanModal.device,
              SENSORS: this.state.card.SENSORS,
              LEAKAGE_SENSORS: this.state.card.LEAKAGE_SENSORS,
              VALVES_NBIOT: this.state.card.VALVES_NBIOT,
              COUNTERS: this.state.card.COUNTERS,
            }}
            hideCallback={this.hidePlanModals}
            parentReloadCallback={this.loadAndParentReload}
            mainUrl={this.state.devicePlanModal.mainUrl}
            devUrl={this.state.devicePlanModal.devUrl}
            devField={this.state.devicePlanModal.devField}
            image={this.state.image}
          />
        </Modal>

        <Modal
          show={this.state.editPlanModalShow}
          onHide={this.hidePlanModals}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Расположение устройств</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                position: "relative",
                textAlign: "center",
                width: "900px",
                margin: "0 auto",
              }}
            >
              <Image
                src={this.state?.image?.url}
                style={{ width: "100%" }}
                onDragOver={this.allowDrop}
              />
              <this.RenderDevicesOnPlan
                items={this.state.card?.SENSORS}
                url="sensor-plan"
              />
              <this.RenderDevicesOnPlan
                items={this.state.card?.LEAKAGE_SENSORS}
                url="leakage-sensor-plan"
              />
              <this.RenderDevicesOnPlan
                items={this.state.card?.VALVES_NBIOT}
                url="valve-nbiot-plan"
              />
              <this.RenderDevicesOnPlan
                items={this.state.card?.COUNTERS}
                url="counter-plan"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.hidePlanModals}>
              Закрыть
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalImage}
          onHide={() => this.setState({ showModalImage: false })}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Изображение плана</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                position: "relative",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Image src={this.state.image?.url} style={{ width: "100%" }} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ showModalImage: false })}
            >
              Отмена
            </Button>
          </Modal.Footer>
        </Modal>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    );
  }
}
