import React, { useState } from "react";

import { Modal, Button } from "react-bootstrap";
import { RequestWithToken } from "../Request";
import { toast } from "react-toastify";

import styles from "./GroupAdd.module.css";

export default function GroupAdd(props) {
  const [devices, setDevices] = useState([]);

  const onAccept = () => {
    RequestWithToken(props.url)
      .post(devices)
      .then((data) => {
        if (data.count === devices.length) {
          toast.success(`Добавлено: ${data.count}`);
          props.onChange();
        } else {
          toast.warning(`Добавлено: ${data.count} из ${devices.length}`);
          props.onChange();
        }
      })
      .catch((e) =>
        toast.error(e.response?.data?.error ?? "Ошибка добавления устройств"),
      );
  };

  return (
    <Modal show={true} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>Групповое добавление устройств</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={styles.help}>
          Внесите в поле серийные номера устройств, разделенные пробелами или
          запятыми
        </p>
        <textarea
          className={styles.textarea}
          onChange={(e) =>
            setDevices(e.target.value.split(/,| |\n|\r/).filter((item) => item))
          }
        />
        <p className={styles.count}>Количество устройств: {devices.length}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onAccept} disabled={!devices.length}>
          Добавить
        </Button>
        <Button variant="secondary" onClick={props.onHide}>
          Отмена
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
