import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function ConfirmModal(props) {
  return (
    <Modal
      show={Object.keys(props).length}
      onHide={props.handleClose}
      size="ms"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>{props.title ?? "Подтвердите действие"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.text ?? "Вы уверены?"}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.handleAccept}>
          {props.acceptText ?? "Да"}
        </Button>
        <Button variant="secondary" onClick={props.handleClose}>
          {props.cancelText ?? "Нет"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function hideConfirmModal(handleCallback = null) {
  if (typeof handleCallback === "function") {
    this.setState({ confirmModal: null }, handleCallback);
  } else {
    this.setState({ confirmModal: null });
  }
}
