import React from "react";

import { RequestWithToken } from "../../Components/General/Request";
import { toast } from "react-toastify";

import { hideConfirmModal } from "../../Components/General/ConfirmModal";

export default class BaseForm extends React.Component {
  idField = "ID"; //в weather он например CITY_ID

  getFormControls(data = [], errors = []) {
    let formControls = this.state.formControls;
    const postData = this.state.postData;

    for (const key in formControls) {
      formControls[key].errorMessage = null;
    }

    for (const key in formControls) {
      if (data) {
        if ("checked" in formControls[key]) {
          formControls[key].checked = Boolean(postData[key] ?? data[key]);
        } else {
          formControls[key].value = postData[key] ?? data[key] ?? null;
        }
      }

      const keyWithId = `${key}_ID`;
      if (errors[key]) {
        formControls[key].errorMessage = errors[key][0] ?? "";
      } else if (errors[keyWithId]) {
        formControls[key].errorMessage = errors[keyWithId][0] ?? "";
      }
    }

    return formControls;
  }

  getNotificationErrors(errors) {
    let notifErrors = [];

    if (typeof errors === "string") {
      notifErrors.push(errors);
    } else if (Array.isArray(errors) || typeof errors === "object") {
      for (const [key, value] of Object.entries(errors)) {
        if (!this.state.formControls[key]) {
          notifErrors.push(typeof value === "string" ? value : [...value]);
        }
      }
    }

    return notifErrors;
  }

  async load(id = this.id, fields = null) {
    this.setState({ requestProcess: true });

    RequestWithToken(
      this.props.server
        ? `retranslate/${this.props.server}/${this.props.mainUrl}/${id}`
        : `${this.props.mainUrl}/${id}`,
    )
      .get()
      .then((data) => this.afterLoad(data.items, fields))
      .catch(() => {
        toast.error("Ошибка запроса");
        this.props.hideCallback();
      })
      .finally(() => this.setState({ requestProcess: false }));
  }

  afterLoad = (items, fields = null) => {
    let card = this.state.card;

    if (fields) {
      fields.forEach(function (field) {
        card[field] = items[field];
      });
    } else {
      card = items;
    }

    this.setState({
      card,
      formControls: this.getFormControls(items),
    });
  };

  async del() {
    this.setState({ requestProcess: true });

    RequestWithToken(
      this.props.server
        ? `retranslate/${this.props.server}/${this.props.mainUrl}/${this.id}`
        : `${this.props.mainUrl}/${this.id}`,
    )
      .del()
      .then((data) => {
        this.afterDelete(data);
        toast.warning("Удалено");
        if (this.props.parentReloadCallback) {
          this.props.parentReloadCallback();
        }
        this.props.hideCallback();
      })
      .catch(() => toast.error("Ошибка удаления"))
      .finally(() => this.setState({ requestProcess: false }));
  }

  afterDelete = (response) => {
    if (this.props.parentSavedCallback) {
      this.props.parentSavedCallback(null);
    }
  };

  getDateIn() {
    return this.state.postData;
  }

  getPath() {
    return this.id ? `${this.props.mainUrl}/${this.id}` : this.props.mainUrl;
  }

  async save(closeModal = false) {
    let formControls = this.state.formControls;

    this.setState({ requestProcess: true, formControls });

    const path = this.getPath();

    RequestWithToken(
      this.props.server ? `retranslate/${this.props.server}/${path}` : path,
    )
      .setData(this.getDateIn())
      .fetch(this.id ? "PUT" : "POST")
      .then((data) => {
        this.afterSave(data);
        toast.success("Сохранено");
        if (this.props.parentReloadCallback) {
          this.props.parentReloadCallback();
        }
        if (closeModal) {
          this.props.hideCallback();
        } else if (data[this.idField]) {
          this.id = data[this.idField];
          this.load(this.id);
        }
      })
      .catch((e) => {
        toast.error("Ошибка сохранения");
        const errors =
          this.getNotificationErrors(e.response?.data?.errors) ?? [];
        this.setState({ errors: e.response?.data?.errors });
        errors.forEach(function (error) {
          toast.warning(error);
        });

        formControls = this.getFormControls(
          null,
          e.response?.data?.errors ?? [],
        );
      })
      .finally(() =>
        this.setState({
          requestProcess: false,
          formControls,
        }),
      );
  }

  afterSave = (response) => {
    this.setState({ postData: {} });
    if (this.props.parentSavedCallback) {
      if (!this.id) {
        this.props.parentSavedCallback(response.ID);
      } else {
        this.props.parentSavedCallback();
      }
    }
  };

  componentDidMount() {
    if (this.id) {
      this.load(this.id);
    }
  }

  cancelClick = (event) => {
    if (Object.keys(this.state?.postData ?? {}).length) {
      this.setState({
        confirmModal: {
          text: "Закрыть без сохранения?",
          handleClose: hideConfirmModal.bind(this),
          handleAccept: this.props.hideCallback.bind(this),
        },
      });
    } else {
      this.props.hideCallback();
    }
  };

  saveClick = (event) => {
    this.save();
  };

  saveCloseClick = (event) => {
    this.save(true);
  };

  delClick = (event) => {
    event.preventDefault();

    this.setState({
      confirmModal: {
        text: "Вы действительно хотите удалить?",
        handleClose: hideConfirmModal.bind(this),
        handleAccept: this.del.bind(this),
      },
    });
  };

  onSaveHandler = () => () => {
    this.save();
  };

  onChangeHandler = () => (e) => {
    const formControls = this.state.formControls;
    const formControl = formControls[e.target.name];
    formControl.value = e.target.value;
    this.setState({
      formControls,
      postData: Object.assign({}, this.state.postData, {
        [e.target.name]: formControl.tVal,
      }),
    });
  };

  onChangeDPHandler = () => (e) => {
    const match = e.target.value.match(/^\d{4}-\d{2}-\d{2}/);

    if (!match && e.target.value !== "") {
      return;
    }

    const value = match ? match[0] : null;

    const formControls = this.state.formControls;
    formControls[e.target.name].value = value ?? "";
    this.setState({
      formControls,
      postData: Object.assign({}, this.state.postData, {
        [e.target.name]: value,
      }),
    });
  };

  coordinatesToState = (coordinates) => {
    if (coordinates !== undefined) {
      const formControls = this.state.formControls;
      formControls.LATITUDE.value = coordinates[0];
      formControls.LONGITUDE.value = coordinates[1];
      const postData = this.state.postData;
      postData.LATITUDE = coordinates[0];
      postData.LONGITUDE = coordinates[1];
      this.setState({ formControls, postData });
    }
  };

  hideMapModal = () => () => {
    this.setState({ mapModalShow: false });
  };

  showMapModal = () => async () => {
    if (!sessionStorage.YANDEX_MAPS_API_KEY) {
      RequestWithToken(`retranslate/${this.props.server}/inf`)
        .get()
        .then((data) => {
          const YANDEX_MAPS_API_KEY = data.items?.YANDEX_MAPS_API_KEY;
          sessionStorage.setItem("YANDEX_MAPS_API_KEY", YANDEX_MAPS_API_KEY);
        });
    }

    if (sessionStorage.YANDEX_MAPS_API_KEY) {
      this.setState({ mapModalShow: true });
    } else {
      toast.warning("Ошибка api key. Попробуйте перезапустить браузер");
    }
  };

  reloadCardAndUpdateParent = () => {
    this.load(this.state.card.ID);
    this.props.parentReloadCallback();
  };

  onChangeCheckboxHandler = () => (e) => {
    const formControls = this.state.formControls;
    formControls[e.target.name].checked = e.target.checked;
    this.setState({
      formControls,
      postData: Object.assign({}, this.state.postData, {
        [e.target.name]: e.target.checked ? 1 : 0,
      }),
    });
  };

  loadAndParentReload = (id = this.id, fields = null) => {
    this.load(id, fields);

    if (this.props.parentReloadCallback) {
      this.props.parentReloadCallback();
    }
  };

  disabledSaveButtons = () => {
    return (
      this.state.requestProcess || Object.keys(this.state.postData).length === 0
    );
  };
}
