import React, { Component } from "react";
import { RequestWithToken } from "../Request";
import "./searchInput.css";

export default class searchInput extends Component {
  typingTimer;

  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      cards: [],
      searchMode: 0,
      showAddNewButton: false,
    };
  }

  search = () => {
    this.load(this.state.searchText);
  };

  async load(searchText = "") {
    this.setState({ errorconnect: false, loading: true });

    RequestWithToken(`retranslate/${this.props.server}/${this.props.path}`)
      .get({
        limit: 200,
        needTotalCount: 0,
        search: searchText,
        searchMode: this.state.searchMode,
      })
      .then((data) =>
        this.setState({
          cards: data.items ?? [],
          totalCount: data.total_count,
          count: data.count,
          loading: false,
          showAddNewButton: data?.items?.length ? false : true,
        }),
      )
      .catch(() =>
        this.setState({
          errorconnect: true,
          totalCount: 0,
          count: 0,
          cards: [],
        }),
      );
  }

  SearchKeyUp = (event) => {
    if (event.keyCode === 13) {
      this.search();
    }
  };

  SearchInput = (event) => {
    let searchText = event.target.value;

    this.setState({ searchText });

    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => this.search(searchText), 500);
  };

  render() {
    return (
      <>
        <div className="searchBox" style={this.props.style}>
          <div className="input-group">
            <input
              type="text"
              placeholder="Поиск"
              autoFocus={true}
              className="form-control"
              value={this.state.searchText}
              onChange={this.SearchInput}
              onKeyUp={this.SearchKeyUp}
            />
            <div className="input-group-append">
              <a
                href={"/server/" + this.props.server + "/Accounts"}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-default"
                type="button"
                title="Открыть справочник в новой вкладке"
              >
                <i className="fas fa-fw fa-list"> </i>
              </a>
            </div>
          </div>
          <div className="searchInputRes">
            {this.state.cards.map((item, idx) => {
              return (
                <div key={idx}>
                  <i
                    className="fas fa-fw fa-eye pointer"
                    title="Просмотреть"
                    onClick={this.props.click.bind(this, item.ID)}
                  >
                    {" "}
                  </i>
                  &nbsp;&nbsp;
                  <span
                    className="pointer"
                    title="Привязать"
                    onClick={this.props.add.bind(this, item.ID)}
                  >
                    {item.TITLE}
                  </span>
                </div>
              );
            })}

            {this.state.showAddNewButton ? (
              <div style={{ textAlign: "center" }}>
                <div>Ничего не найдено</div>
                <div
                  className="btn btn-default"
                  onClick={this.props.click.bind(this, null)}
                >
                  Cоздать новый
                </div>
              </div>
            ) : null}

            {this.state.searchText.length === 0 ? (
              <div style={{ textAlign: "center" }}>
                Введите символы для поиска
              </div>
            ) : null}
          </div>
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
            }}
            onClick={this.props.closeSearch}
          ></div>
        </div>
      </>
    );
  }
}
