import React from "react";
import { Modal, Button } from "react-bootstrap";
import changelog from "../../changelog.json";
import metadata from "../../metadata.json";

export default class About extends React.Component {
  state = {
    showModal: false,
  };

  onClickHandler = () => () => {
    this.setState({ showModal: true });
  };

  onCloseModal = () => () => {
    this.setState({ showModal: false });
  };

  changelogLoop = () => {
    changelog.map();
  };

  render() {
    return (
      <>
        <h1 className={"d-none d-sm-block"}>О системе</h1>
        <div className="card contentInner">
          <div className="card-body">
            <p>
              Панель управления данными АСКУЭ «Квант-Энерго» (ПУД) представляет
              собой совокупность веб-страниц пользовательского интерфейса,
              взаимодействующих с серверной частью программного модуля и
              устройством пользователя посредством протокола HTTP и
              веб-браузера.
            </p>
            <p>
              ПУД предназначен для сбора, обобщения, учета, контроля и анализа
              сведений о производстве, распределении и потреблении
              энергоресурсов (электрическая и тепловая энергия, холодная вода,
              горячая вода, природный газ) с приборов и узлов учета
              энергоресурсов, а также из информационных систем ресурсоснабжающих
              организаций.
            </p>
          </div>
        </div>

        <div className="card contentInner" style={{ marginTop: 10 }}>
          <div className="card-body">
            <h2>Контакты</h2>
            <p>
              Телефон технической поддержки:{" "}
              <a href="tel:88002507914">8 (800) 250-79-14</a>
              <br />
              Адрес электронной почты технической поддержки:{" "}
              <a href="mailto:admin@kvant.online">admin@kvant.online</a>
            </p>
          </div>
        </div>
        <div className="card contentInner" style={{ marginTop: 10 }}>
          <div className="card-body">
            <p>{`Версия: ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}`}</p>
            <Button
              variant="secondary"
              size="sm"
              onClick={this.onClickHandler()}
            >
              История изменений
            </Button>
          </div>
        </div>
        <Modal show={this.state.showModal} onHide={this.onCloseModal()}>
          <Modal.Header closeButton>
            <Modal.Title>История изменений</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {changelog.map((el) => {
              return (
                <>
                  <b>{el.version}</b>
                  <p>{el.description}</p>
                </>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              disabled={this.state.requestProcess}
              onClick={this.onCloseModal()}
            >
              Закрыть
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
