import Base from "../Base";
import React from "react";
import LsItem from "./LsItem";
import LsForm from "./LsForm";
import TableSearch from "../../../Components/General/TableSearch/TableSearch";
import { Modal } from "react-bootstrap";
import Error from "../../../Components/General/Alerts/error";
import AddBtn from "../../../Components/General/AddBtn";

import SearchInputObj from "../../../Components/General/SearchInputs/searchInputObj";
import Counter from "../../../Components/Devices/Counter";
import LeakageSensor from "../../../Components/Devices/LeakageSensor";

export default class Accounts extends Base {
  static mainUrl = "lic-schet";
  mainUrl = "lic-schet";

  filter = (
    <>
      <SearchInputObj
        style={{ left: "15px", right: "15px" }}
        size={12}
        label="Адрес"
        source="address"
        server={this.props.server}
        onSelectItem={(item) => this.setFilter("address", item.ID)}
        searchMode={2}
      />
      <SearchInputObj
        style={{ left: "15px", right: "15px" }}
        size={12}
        label="Организация"
        source="org"
        server={this.props.server}
        onSelectItem={(item) => this.setFilter("org", item.ID)}
      />
      <SearchInputObj
        style={{ left: "15px", right: "15px" }}
        size={12}
        label="Плательщик"
        source="client"
        server={this.props.server}
        onSelectItem={(item) => this.setFilter("client", item.ID)}
      />
      <SearchInputObj
        style={{ left: "15px", right: "15px" }}
        size={12}
        label="ПУ"
        source="counter"
        server={this.props.server}
        onSelectItem={(item) => this.setFilter("counter", item.ID)}
        render={(item) => <Counter item={item} />}
      />
      <SearchInputObj
        style={{ left: "15px", right: "15px" }}
        size={12}
        label="ДП"
        source="leakage-sensor"
        server={this.props.server}
        onSelectItem={(item) => this.setFilter("leakageSensor", item.ID)}
        render={(item) => <LeakageSensor item={item} />}
      />
    </>
  );

  render() {
    return (
      <div className="inmodule">
        <TableSearch
          filter={this.filter}
          searchParams={this.searchParams}
          setParentCallback={() => this.setCallback()}
        />

        <div className="centerCount">
          Показано {this.state.cards.length} из {this.state.totalCount}
        </div>

        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col" style={{ width: "500px" }}>
                Наименование
              </th>
              <th scope="col" style={{ width: "250px" }}>
                Плательщик
              </th>
              <th scope="col">Адрес</th>
              <th scope="col" style={{ width: "150px" }}>
                с/н ПУ
              </th>
              <th scope="col" style={{ width: "250px" }}>
                Организация
              </th>
              <th scope="col" style={{ width: "150px" }}>
                Параметры
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.cards.map((item) => {
              return (
                <LsItem key={item.ID} info={item} onClick={this.showModal} />
              );
            })}
          </tbody>
        </table>

        {this.state.cards.length < this.state.totalCount
          ? this.getMoreButton()
          : null}

        <div style={{ height: "60px" }}></div>

        <AddBtn onClick={this.newClick()} />

        <Modal
          show={this.state.modalShow}
          onHide={this.hideEditModal}
          size="xl"
          fullscreen="lg-down"
        >
          <LsForm
            id={this.state.modalItemId}
            hideCallback={this.hideEditModal}
            parentReloadCallback={this.reloadCallback}
            server={this.props.server}
            mainUrl={this.mainUrl}
          />
        </Modal>

        {this.state.errorconnect ? <Error /> : null}
      </div>
    );
  }
}
