import React, { Component } from "react";
import MkdBsItem from "./mkdBsItem";
import MkdCounterItem from "./mkdCounterItem";

export default class MkdCell extends Component {
  state = {
    searchText: "",
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.searchText !== this.props.searchText) {
      this.setState({
        searchText: this.props.searchText,
      });
    }
  }

  render() {
    this.counter_list = [];

    for (var counter in this.props.cell.IN.COUNTERS) {
      this.counter_list.push({
        ID: this.props.cell.IN.COUNTERS[counter].ID,
        CODE: this.props.cell.IN.COUNTERS[counter].CODE
          ? this.props.cell.IN.COUNTERS[counter].CODE
          : "",
        KVARTIRA: this.props.cell.IN.COUNTERS[counter].KVARTIRA
          ? this.props.cell.IN.COUNTERS[counter].KVARTIRA
          : "",
        ICON: this.props.cell.IN.COUNTERS[counter].ICON,
        IS_BALANCE: this.props.cell.IN.COUNTERS[counter].IS_BALANCE,
        ID_LINK: this.props.cell.IN.COUNTERS[counter].ID_LINK,
        COMMENT: this.props.cell.IN.COUNTERS[counter].COMMENT,
        RSSI: this.props.cell.IN.COUNTERS[counter].RSSI,
      });
    }

    this.bs_list = [];
    for (var bs in this.props.cell.IN.MODEMS) {
      this.bs_list.push({
        ID: this.props.cell.IN.MODEMS[bs].ID,
        CODE: this.props.cell.IN.MODEMS[bs].CODE
          ? this.props.cell.IN.MODEMS[bs].CODE
          : "",
        ID_LINK: this.props.cell.IN.MODEMS[bs].ID_LINK,
        TITLE: this.props.cell.IN.MODEMS[bs].TITLE
          ? this.props.cell.IN.MODEMS[bs].TITLE
          : "",
        COMMENT: this.props.cell.IN.MODEMS[bs].COMMENT,
      });
    }

    return (
      <td
        id={this.props.cell.IN.ID}
        className="drop"
        onClick={this.props.CellClick}
        onDrop={this.props.drop}
        onDragOver={this.props.allowDrop}
      >
        {this.bs_list.map((bs) => {
          if (
            bs.CODE.includes(this.state.searchText) ||
            bs.TITLE.includes(this.state.searchText)
          ) {
            return (
              <MkdBsItem
                key={bs.ID}
                parent="cell"
                bs={bs}
                BsClick={this.props.BsClick}
                onDragStart={this.props.drag}
                onDragEnd={this.props.dragend}
                onDragOver={this.props.DallowDrop}
              />
            );
          }
          return null;
        })}

        {this.counter_list.map((counter) => {
          if (
            counter.CODE.includes(this.state.searchText) ||
            counter.KVARTIRA.includes(this.state.searchText)
          ) {
            return (
              <MkdCounterItem
                key={counter.ID}
                parent="cell"
                counter={counter}
                CounterClick={this.props.CounterClick}
                onDragStart={this.props.drag}
                onDragEnd={this.props.dragend}
                onDragOver={this.props.DallowDrop}
              />
            );
          }
          return null;
        })}
      </td>
    );
  }
}
