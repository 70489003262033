import styles from "../../../Components/Map/Map.module.css";

export default function getDeviceIconStyle(icon) {
  switch (icon) {
    case "sensor":
      return styles.circleOnPlan;
    case "leakage-sensor":
      return styles.leakageSensorOnPlan;
    case "valve-nbiot":
      return styles.valveNbiotOnPlan;
    case "electro":
      return styles.counterElectroOnPlan;
    case "w-cold":
      return styles.counterColdOnPlan;
    case "w-hot":
      return styles.counterHotOnPlan;
    case "gaz":
      return styles.counterGazOnPlan;
    case "colorimetr":
      return styles.counterColorOnPlan;
    default:
      return styles.counterElectroOnPlan;
  }
}
