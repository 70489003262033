import React from "react";
import { Form } from "react-bootstrap";

export default function DatePicker({ value, onChange, errorMessage, title }) {
  return (
    <Form.Group>
      <Form.Label>{title}</Form.Label>
      <Form.Control type="date" value={value ?? ""} onChange={onChange} />
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
