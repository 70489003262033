import Base from "../inServer/Base";

import React from "react";
import ModuleItem from "./ModuleItem";
import ModuleForm from "./ModuleForm";
import TableSearch from "../../Components/General/TableSearch/TableSearch";
import { Modal } from "react-bootstrap";
import Error from "../../Components/General/Alerts/error";
import AddBtn from "../../Components/General/AddBtn";
import { userCan } from "../../Utils/Helpers";

export default class ModuleList extends Base {
  mainUrl = "module";

  render() {
    return (
      <>
        <h1 className={"d-none d-sm-block"}>Работа со справочниками</h1>
        <div aria-label="breadcrumb" className="navInSrv">
          <ol className="breadcrumb"></ol>
        </div>
        <div className="inmodule">
          <TableSearch
            searchParams={this.searchParams}
            setParentCallback={() => this.setCallback()}
          />

          <div className="centerCount">
            Показано {this.state.cards.length} из {this.state.totalCount}
          </div>

          <table className="table table-hover">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Наименование</th>
                <th scope="col" style={{ width: "250px" }}>
                  Ссылка
                </th>
                <th scope="col" style={{ width: "150px" }}>
                  Параметры
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.cards.map((item) => {
                return (
                  <ModuleItem
                    key={item.id}
                    info={item}
                    onClick={this.showModal}
                  />
                );
              })}
            </tbody>
          </table>

          {this.state.cards.length < this.state.totalCount
            ? this.getMoreButton()
            : null}

          <div style={{ height: "60px" }}></div>

          {userCan(this.mainUrl, "create") ? (
            <AddBtn onClick={this.newClick()} />
          ) : null}

          <Modal
            show={this.state.modalShow}
            onHide={this.hideEditModal}
            size="lg"
          >
            <ModuleForm
              id={this.state.modalItemId}
              hideCallback={this.hideEditModal}
              parentReloadCallback={this.reloadCallback}
              mainUrl={this.mainUrl}
            />
          </Modal>

          {this.state.errorconnect ? <Error /> : null}
        </div>
      </>
    );
  }
}
