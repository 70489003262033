import React from "react";

export default class MkdBsItem extends React.Component {
  render() {
    return (
      <>
        <span
          className="badge mkd-bs"
          id={
            "b|" +
            this.props.parent +
            "|" +
            this.props.bs.ID +
            "|" +
            this.props.bs.ID_LINK
          }
          draggable="true"
          code={this.props.bs.CODE}
          onClick={() => {
            this.props.BsClick(this.props.bs.ID);
          }}
          title={this.props.bs.COMMENT}
          onDragStart={this.props.onDragStart}
          onDragEnd={this.props.onDragEnd}
          onDragOver={this.props.onDragOver}
        >
          <i className="s-icon icon-bs"> </i>{" "}
          {this.props.bs.TITLE ? this.props.bs.TITLE : this.props.bs.CODE}
        </span>
      </>
    );
  }
}
