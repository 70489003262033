import BaseForm from "../BaseForm";

import React from "react";
import { RequestWithToken } from "../../../Components/General/Request";
import { toast } from "react-toastify";
import LsForm from "../accounts/LsForm";
import SearchInput from "../../../Components/General/SearchInputs/searchInput";
import {
  Button,
  Form,
  Modal,
  Nav,
  Dropdown,
  SplitButton,
  ButtonGroup,
  Row,
} from "react-bootstrap";
import SearchInputObj from "../../../Components/General/SearchInputs/searchInputObj";
import SearchInputAddr from "../../../Components/General/SearchInputs/searchInputAddr";
import Tab from "../../../Components/General/Tab";
import Map from "../../../Components/Map/Map";
import "../../../transfer-tech.css";

import FormControlsRender from "../../../Utils/FormControlsRender";
import {
  Text,
  TextArea,
  Numeric,
  Checkbox,
  DatePicker,
} from "../../../Utils/FormControlsPresets";
import ZoneForm from "../zones/ZoneForm";
import Zone from "../../../Components/Zone";
import ModemForm from "../modems/ModemForm";
import Modem from "../../../Components/Devices/Modem";
import ConfirmModal, {
  hideConfirmModal,
} from "../../../Components/General/ConfirmModal";

export default class CounterForm extends BaseForm {
  constructor(props) {
    super(props);

    this.state = {
      navKey: "1",
      requestProcess: false,
      card: {
        KOEF_TRANSFORM: 1,
      },
      formControls: {
        CODE: Text("Серийный номер"),
        DATE_OF_MANUFACTURE: DatePicker("Дата производства"),
        KOEF_TRANSFORM: Numeric(
          "КТ",
          0,
          9999999999.99999,
          /^\d{0,10}(\.?\d{0,2})?$/,
          "Коэффициент трансформации > 1",
        ),
        MEJPR_GOST: Numeric(
          "Меж. инт.",
          0,
          100,
          /^\d{0,10}$/,
          "Межповерочный интервал",
        ),
        START_VALUE_1: Numeric(
          "Тариф 1",
          0,
          9999999999.99999,
          /^\d{0,10}(\.?\d{0,4})?$/,
          "Показания при установке",
        ),
        START_VALUE_2: Numeric(
          "Тариф 2",
          0,
          9999999999.99999,
          /^\d{0,10}(\.?\d{0,4})?$/,
          "Показания при установке",
        ),
        START_VALUE_3: Numeric(
          "Тариф 3",
          0,
          9999999999.99999,
          /^\d{0,10}(\.?\d{0,4})?$/,
          "Показания при установке",
        ),
        START_VALUE_4: Numeric(
          "Тариф 4",
          0,
          9999999999.99999,
          /^\d{0,10}(\.?\d{0,4})?$/,
          "Показания при установке",
        ),
        START_VALUE: Numeric(
          "Общий тариф",
          0,
          9999999999.99999,
          /^\d{0,10}(\.?\d{0,4})?$/,
          "Показания при установке",
        ),
        LATITUDE: Numeric(
          "Коор. (широта)",
          -90,
          90,
          /^\-?\d{0,2}(\.?\d{0,6})?$/,
        ), //eslint-disable-line
        LONGITUDE: Numeric(
          "Коор. (долгота)",
          -180,
          180,
          /^\-?\d{0,3}(\.?\d{0,6})?$/,
        ), //eslint-disable-line
        COMMENT: TextArea("Служебный комментарий"),
        IS_BALANS: Checkbox("Балансовый"),
        IS_SYS_KVANT_ENERGO: Checkbox("Возможность управления"),
        IS_FIX_VMESH: Checkbox("Нештатки"),
        INSTALLATION_DATE: DatePicker("Дата установки"),
        DATE_PROVER: DatePicker("Дата поверки"),
        DATE_PROVER_NEXT: DatePicker("След. поверка"),
        STOP_TIME: DatePicker("Дата снятия с учета"),
        PRIM: TextArea("Примечание"),
      },
      thisLs: null,
      modalLsTitle: null,
      old_id: null,
      searchLs: false,
      showLsModal: false,
      typeSchet: null,
      postData: {},
      mapModalShow: false,
      thisZone: null,
      zoneModalShow: false,
      thisBs: null,
      bsModalShow: false,
    };

    this.id = props.id; //для обновления ID
  }

  lsClick = () => {
    this.clickLsOpen(this.state.card.LS.ID);
  };

  copyCB = (text, event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(text);
    toast.success("Скопировано " + text);
    return false;
  };

  afterLoad = (items) => {
    this.setState({
      card: items,
      thisLs: items.LS ? items.LS.ID : null,
      typeSchet: items.TYPE_SCHET,
      formControls: this.getFormControls(items),
    });
  };

  getDateIn() {
    return {
      old_id: this.state.old_id,
      has_embedded_modem: 0,
      counter: this.state.postData,
    };
  }

  async removeLsLoad(idLink) {
    RequestWithToken(`retranslate/${this.props.server}/lic-counter/${idLink}`)
      .del()
      .then(() => {
        toast.warning("Связь удалена");
        this.load(this.state.card.ID);
      })
      .catch(() => toast.error("Ошибка удаления связи"));
  }

  async addLsLoad(idLs) {
    RequestWithToken(`retranslate/${this.props.server}/lic-counter`)
      .post({
        OWNER: idLs,
        COUNTER: this.state.card.ID,
        IS_ACTIVE: "1",
      })
      .then(() => {
        toast.success("Связь сохранена");
        this.load(this.state.card.ID);
      })
      .catch((e) => {
        toast.error("Ошибка сохранения связи");
        for (var err_v in e.response?.data?.errors ?? []) {
          toast.warning(e.response.data.errors[err_v]);
        }
      });
  }

  createDoubleClick = (event) => {
    this.id = null;

    let card = this.state.card;
    const cardId = this.state.card.ID;
    delete card["ID"];
    delete card["CODE"];
    delete card["LS"];

    this.setState({
      old_id: cardId,
      card,
      postData: card,
    });
    toast.info("Данные скопированы. Заполните поля и сохраните дубликат");
  };

  delClick = (event) => {
    event.preventDefault();

    let text = this.state.card.LS
      ? `Счетчик с серийным номером ${this.state.card.CODE} привязан к лицевому счету ${this.state.card.LS.TITLE}. `
      : null;
    text += "Вы действительно хотите удалить?";

    this.setState({
      confirmModal: {
        text,
        handleClose: hideConfirmModal.bind(this),
        handleAccept: hideConfirmModal.bind(this, this.del.bind(this)),
      },
    });
  };

  savedls = (ls) => {
    if (ls) {
      this.addLsLoad(ls);
    } else if (ls === null && this.state.card?.LS?.ID_LINK) {
      this.removeLs(this.state.card?.LS?.ID_LINK);
    }

    this.load();
  };

  closeSearch = () => {
    this.setState({ searchLs: false });
  };

  addLs = (lsid) => {
    this.addLsLoad(lsid);
    this.setState({
      searchLs: false,
    });
  };

  removeLs = (idLinkLs) => {
    this.removeLsLoad(idLinkLs);
  };

  reAddLs = (idLinkLs) => {
    this.removeLs(idLinkLs);
    this.addLsBt();
  };

  addNewLsBt = () => {
    this.setState({ thisLs: null, showLsModal: true });
  };

  clickLsOpen = (lsid = null) => {
    this.setState({ thisLs: lsid, showLsModal: true });
  };

  addLsBt = (event) => {
    this.setState({ searchLs: true });
  };

  hideLsModal = () => () => {
    this.setState({ thisLs: null, showLsModal: false });
  };

  clickZoneOpen = (id = null) => {
    this.setState({ thisZone: id, zoneModalShow: true });
  };

  hideZoneModal = () => {
    this.setState({ thisZone: null, zoneModalShow: false });
  };

  clickBsOpen = (id = null) => {
    this.setState({ thisBs: id, bsModalShow: true });
  };

  hideBsModal = () => {
    this.setState({ thisBs: null, bsModalShow: false });
  };

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>
            {(this.state.card.ID ? "Редактирование" : "Создание") +
              " прибора учета"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <div className="input-group guid">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    GUID
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  disabled="disabled"
                  value={this.state.card.ID ?? ""}
                />
              </div>

              <div className="col-md-4">
                {this.state.card.LS ? (
                  <>
                    <SplitButton
                      as={ButtonGroup}
                      variant="default"
                      title={this.state.card.LS?.TITLE}
                      onClick={this.lsClick}
                      className="lsInCounter"
                    >
                      <Dropdown.Item
                        as="a"
                        role="button"
                        onClick={this.reAddLs.bind(
                          this,
                          this.state.card.LS.ID_LINK,
                        )}
                      >
                        Сменить ЛС
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="a"
                        role="button"
                        onClick={this.removeLs.bind(
                          this,
                          this.state.card.LS.ID_LINK,
                        )}
                      >
                        Отвязать ЛС
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="a"
                        role="button"
                        onClick={this.copyCB.bind(
                          this,
                          this.state.card.LS.ID_LINK,
                        )}
                      >
                        Копировать в буфер
                      </Dropdown.Item>
                    </SplitButton>
                  </>
                ) : this.state.card.ID ? (
                  <>
                    <SplitButton
                      as={ButtonGroup}
                      variant="default"
                      title="Нет ЛС"
                      onClick={this.addLsBt}
                      className="lsInCounter"
                    >
                      <Dropdown.Item
                        as="a"
                        role="button"
                        onClick={this.addLsBt}
                      >
                        Привязать ЛС
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="a"
                        role="button"
                        onClick={this.addNewLsBt}
                      >
                        Создать ЛС
                      </Dropdown.Item>
                    </SplitButton>
                    {this.state.searchLs ? (
                      <SearchInput
                        style={{
                          top: "15px",
                          left: "auto",
                          right: "auto",
                          width: "calc(33% - 20px)",
                        }}
                        server={this.props.server}
                        closeSearch={this.closeSearch}
                        add={this.addLs}
                        click={this.clickLsOpen}
                        path="lic-schet"
                      />
                    ) : null}
                  </>
                ) : null}
              </div>
            </Row>
            <Row>
              {FormControlsRender(
                "CODE",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-2",
              )}

              <SearchInputObj
                label="Вид ресурса"
                source="type-counter"
                size="3"
                server={this.props.server}
                render={(item) => (
                  <>
                    <span className={`fas res16 res-${item.ICON_NAME}`}> </span>
                    {item.RES_NAME}
                  </>
                )}
                onSelectItem={(item) => {
                  const postData = this.state.postData;
                  postData.TYPE_SCHET = item.ID ?? null;
                  postData.CLASS_COUNTER = null;
                  const card = this.state.card;
                  card.CLASS_COUNTER = "";
                  card.CLASS_COUNTER_TITLE = "";
                  this.setState({ typeSchet: item.ID ?? null, card, postData });
                }}
                defaultItem={{
                  ID: this.state.card.TYPE_SCHET,
                  RES_NAME: this.state.card.TYPE_SCHET_RES_NAME,
                  ICON_NAME: this.state.card.TYPE_SCHET_ICON_NAME,
                }}
              />

              <SearchInputObj
                label="Модель"
                source="class-counter"
                size="3"
                server={this.props.server}
                onSelectItem={(item) => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, {
                      CLASS_COUNTER: item.ID ?? null,
                    }),
                  });
                }}
                defaultItem={{
                  ID: this.state.card.CLASS_COUNTER,
                  TITLE: this.state.card.CLASS_COUNTER_TITLE,
                }}
                filter={{ resType: this.state.typeSchet }}
                disabled={true}
              />

              <SearchInputObj
                label="Встроенный модем"
                source="modem"
                server={this.props.server}
                size="4"
                render={(item) => {
                  let icon = item.TECH_ICON ? (
                    <i
                      className={`transfer-tech details-tech ${item.TECH_ICON}`}
                    ></i>
                  ) : null;
                  return (
                    <>
                      {" "}
                      {icon} {item.SERNUMBER}{" "}
                      {item.CLASS_TITLE ? "(" + item.CLASS_TITLE + ")" : ""}
                    </>
                  );
                }}
                onSelectItem={(item) => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, {
                      EMBEDDED_MODEM_ID: item.ID ?? null,
                    }),
                  });
                }}
                defaultItem={this.state.card.EMBEDDED_MODEM}
              />
            </Row>
            <Row>
              {FormControlsRender(
                "DATE_OF_MANUFACTURE",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-2",
              )}

              <SearchInputObj
                label="Часовой пояс"
                source="locale"
                server={this.props.server}
                onSelectItem={(item) => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, {
                      TIMEZONE: item.ID ?? null,
                    }),
                  });
                }}
                defaultItem={{
                  ID: this.state.card.TIMEZONE,
                  TITLE: this.state.card.TIMEZONE_TITLE,
                }}
              />

              {FormControlsRender(
                "KOEF_TRANSFORM",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-2",
              )}
              {FormControlsRender(
                "INSTALLATION_DATE",
                this.state.formControls,
                this.onChangeDPHandler,
                this.onSaveHandler(),
                "col-md-2",
              )}
              {FormControlsRender(
                "DATE_PROVER",
                this.state.formControls,
                this.onChangeDPHandler,
                this.onSaveHandler(),
                "col-md-2",
              )}
            </Row>
            <Row>
              {FormControlsRender(
                "MEJPR_GOST",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-2",
              )}
              {FormControlsRender(
                "DATE_PROVER_NEXT",
                this.state.formControls,
                null,
                null,
                "col-md-2",
                { disabled: true },
              )}
              {FormControlsRender(
                "STOP_TIME",
                this.state.formControls,
                this.onChangeDPHandler,
                this.onSaveHandler(),
                "col-md-2",
              )}
              {FormControlsRender(
                "IS_BALANS",
                this.state.formControls,
                this.onChangeCheckboxHandler,
                this.onSaveHandler(),
                "col-md-2",
              )}
            </Row>
            <Row>
              <div className="tarif_group">
                <div className="tarif_group_title">Показания при установке</div>
                <Row>
                  {FormControlsRender(
                    "START_VALUE_1",
                    this.state.formControls,
                    this.onChangeHandler,
                    this.onSaveHandler(),
                    "col-md-2",
                  )}
                  {FormControlsRender(
                    "START_VALUE_2",
                    this.state.formControls,
                    this.onChangeHandler,
                    this.onSaveHandler(),
                    "col-md-2",
                  )}
                  {FormControlsRender(
                    "START_VALUE_3",
                    this.state.formControls,
                    this.onChangeHandler,
                    this.onSaveHandler(),
                    "col-md-2",
                  )}
                  {FormControlsRender(
                    "START_VALUE_4",
                    this.state.formControls,
                    this.onChangeHandler,
                    this.onSaveHandler(),
                    "col-md-2",
                  )}
                  {FormControlsRender(
                    "START_VALUE",
                    this.state.formControls,
                    this.onChangeHandler,
                    this.onSaveHandler(),
                    "col-md-4",
                    { disabled: true },
                  )}
                </Row>
              </div>
            </Row>
            <Row>
              <SearchInputAddr
                key={this.state.card?.ADDRESS?.ID ?? "address"}
                label="Место установки"
                size="12"
                server={this.props.server}
                closeSearch={this.closeSearchAddr}
                defaultItem={this.state.card?.ADDRESS ?? {}}
                onSelectItem={(item) => {
                  this.setState({
                    postData: Object.assign({}, this.state.postData, {
                      ADDRESS: item.ID ?? null,
                    }),
                  });
                }}
                addAddr={this.addAddr}
              />
            </Row>
            <Row>
              {FormControlsRender(
                "COMMENT",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-6",
              )}
              {FormControlsRender(
                "PRIM",
                this.state.formControls,
                this.onChangeHandler,
                this.onSaveHandler(),
                "col-md-6",
              )}
            </Row>

            <div style={{ height: "15px" }}></div>

            <Nav
              variant="tabs"
              defaultActiveKey={this.state.navKey}
              onSelect={(key) => this.setState({ navKey: key })}
            >
              <Nav.Item>
                <Nav.Link eventKey="1">Зоны</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="2">БС и МТ</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="3">Дополнительно</Nav.Link>
              </Nav.Item>
            </Nav>

            <div className="tab-content tabContent" id="nav-tabContent">
              <span
                style={{ display: this.state.navKey !== "1" ? "none" : null }}
              >
                <Tab
                  cards={this.state.card?.ZONES ?? []}
                  onChangeHandler={() =>
                    this.loadAndParentReload(this.id, ["ZONES"])
                  }
                  renderFunction={(item) => (
                    <Zone
                      item={item}
                      onClick={this.clickZoneOpen.bind(this, item.ID)}
                    />
                  )}
                  renderSearchFunction={(item) => <Zone item={item} />}
                  addUrl={`retranslate/${this.props.server}/zone-counter`}
                  addDataIn={(item) => ({ COUNTER: this.id, OWNER: item.ID })}
                  deleteUrl={(item) =>
                    `retranslate/${this.props.server}/zone-counter/${item.ID_LINK}`
                  }
                  searchUrl={`retranslate/${this.props.server}/zone`}
                  filter={{ noCounter: this.id ?? null }}
                  disabled={!this.id}
                />
              </span>
              <span
                style={{ display: this.state.navKey !== "2" ? "none" : null }}
              >
                <Tab
                  cards={this.state.card?.MODEMS ?? []}
                  onChangeHandler={() =>
                    this.loadAndParentReload(this.id, ["MODEMS"])
                  }
                  renderFunction={(item) => (
                    <Modem
                      item={item}
                      onClick={this.clickBsOpen.bind(this, item.ID)}
                    />
                  )}
                  renderSearchFunction={(item) => <Modem item={item} />}
                  addUrl={`retranslate/${this.props.server}/modem-counter`}
                  addDataIn={(item) => ({ COUNTER: this.id, OWNER: item.ID })}
                  deleteUrl={(item) =>
                    `retranslate/${this.props.server}/modem-counter/${item.ID_LINK}`
                  }
                  searchUrl={`retranslate/${this.props.server}/modem`}
                  filter={{ noCounter: this.id ?? null }}
                  disabled={!this.id}
                />
              </span>
              <span
                style={{ display: this.state.navKey !== "3" ? "none" : null }}
              >
                <Row>
                  {FormControlsRender(
                    "LATITUDE",
                    this.state.formControls,
                    this.onChangeHandler,
                    this.onSaveHandler(),
                    "col-md-5",
                  )}
                  {FormControlsRender(
                    "LONGITUDE",
                    this.state.formControls,
                    this.onChangeHandler,
                    this.onSaveHandler(),
                    "col-md-5",
                  )}

                  <div className="col-md-2">
                    <Button
                      variant="secondary"
                      style={{ marginTop: "32px" }}
                      onClick={this.showMapModal()}
                    >
                      Карта
                    </Button>
                  </div>

                  {FormControlsRender(
                    "IS_SYS_KVANT_ENERGO",
                    this.state.formControls,
                    this.onChangeCheckboxHandler,
                    this.onSaveHandler(),
                    "col-md-6",
                  )}
                  {FormControlsRender(
                    "IS_FIX_VMESH",
                    this.state.formControls,
                    this.onChangeCheckboxHandler,
                    this.onSaveHandler(),
                    "col-md-6",
                  )}
                </Row>
              </span>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {this.state.card.ID ? (
            <>
              <Button variant="danger" onClick={this.delClick}>
                Удалить
              </Button>
              <Button variant="secondary" onClick={this.createDoubleClick}>
                Создать дубликат
              </Button>
            </>
          ) : null}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            disabled={this.disabledSaveButtons()}
            variant="primary"
            onClick={this.saveClick}
          >
            Сохранить и продолжить
          </Button>
          <Button
            disabled={this.disabledSaveButtons()}
            variant="primary"
            onClick={this.saveCloseClick}
          >
            Сохранить и закрыть
          </Button>
          <Button
            disabled={this.state.requestProcess}
            variant="secondary"
            onClick={this.cancelClick}
          >
            Отмена
          </Button>
        </Modal.Footer>

        <Modal
          show={this.state.showLsModal}
          onHide={this.hideLsModal()}
          size="xl"
        >
          <LsForm
            server={this.props.server}
            id={this.state.thisLs}
            hideCallback={this.hideLsModal()}
            parentSavedCallback={this.savedls}
            mainUrl="lic-schet"
          />
        </Modal>

        <Modal
          show={this.state.mapModalShow}
          onHide={this.hideMapModal()}
          size="xl"
        >
          <Map
            coordinates={[
              this.state.formControls.LATITUDE.value,
              this.state.formControls.LONGITUDE.value,
            ]}
            hideCallback={(coordinates) => {
              this.coordinatesToState(coordinates);
              this.hideMapModal()();
            }}
          />
        </Modal>

        <Modal
          show={this.state.zoneModalShow}
          onHide={this.hideZoneModal}
          size="xl"
        >
          <ZoneForm
            server={this.props.server}
            id={this.state.thisZone}
            hideCallback={this.hideZoneModal}
            parentReloadCallback={() =>
              this.loadAndParentReload(this.id, ["ZONES"])
            }
            mainUrl="zone"
          />
        </Modal>

        <Modal
          show={this.state.bsModalShow}
          onHide={this.hideBsModal}
          size="xl"
        >
          <ModemForm
            server={this.props.server}
            id={this.state.thisBs}
            hideCallback={this.hideBsModal}
            parentReloadCallback={() =>
              this.loadAndParentReload(this.id, ["MODEMS"])
            }
            mainUrl="modem"
          />
        </Modal>

        <ConfirmModal {...this.state.confirmModal} />
      </>
    );
  }
}
