import BaseItem from "../BaseItem";

import React from "react";
import { arrayObjToText } from "../../../Utils/Helpers";

export default class LeakageItem extends BaseItem {
  render() {
    return (
      <tr
        onClick={this.onClick.bind(this, this.props.info.ID)}
        style={{ cursor: "pointer" }}
      >
        <th>{this.props.info.SERIAL_NUMBER}</th>

        <td>{this.props.info.MODEL.TITLE ?? ""}</td>

        <td title={arrayObjToText(this.props.info.ZONES, "TITLE", 250)}>
          <small>
            {this.props.info.ZONES
              ? this.props.info.ZONES.length + " : "
              : null}{" "}
            {arrayObjToText(this.props.info.ZONES, "TITLE")}
          </small>
        </td>

        <td>
          <div>
            <i
              className="fas fa-fw fa-clipboard"
              title="Скопировать GUID"
              onClick={this.copyCB.bind(this, this.props.info.ID)}
            >
              {" "}
            </i>
            <i
              className="fas fa-fw fa-clipboard-list"
              title="Скопировать серийный номер"
              onClick={this.copyCB.bind(this, this.props.info.SERIAL_NUMBER)}
            >
              {" "}
            </i>
            {this.props.info.COMMENT ? (
              <i
                className="fas fa-fw fa-comment-dots"
                title={this.props.info.COMMENT}
              >
                {" "}
              </i>
            ) : (
              <i className="fas fa-fw"> </i>
            )}
          </div>
        </td>
      </tr>
    );
  }
}
