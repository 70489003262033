import React from "react";

import styles from "./Device.module.css";

export default function Device(props) {
  const device = props.device;

  return (
    <span
      onClick={props.onClick ?? null}
      style={props.onClick ? { cursor: "pointer" } : null}
      title={props.onClick ? "Просмотреть" : null}
    >
      <i className={`${styles?.devIcon} ${styles[device?.type]}`}> </i>{" "}
      {device?.title}
    </span>
  );
}
