import React from "react";
import BaseItem from "../BaseItem";

export default class WeatherItem extends BaseItem {
  render() {
    return (
      <tr
        onClick={this.onClick.bind(this, this.props.info.CITY_ID)}
        style={{ cursor: "pointer" }}
      >
        <th>{this.props.info.TITLE}</th>
        <td>
          <small
            style={{ whiteSpace: "nowrap", overflow: "hidden", width: "50px" }}
          >
            {this.props.info.CITY_ID}
          </small>
        </td>
        <td>{this.props.info.INTERVAL}</td>
        <td>
          <div>
            <i
              className="fas fa-fw fa-clipboard-list"
              title="Скопировать дентификатор населенного пункта в OpenWeatherMap"
              onClick={this.copyCB.bind(this, this.props.info.CITY_ID)}
            >
              {" "}
            </i>
          </div>
        </td>
      </tr>
    );
  }
}
